<template>
  <div id="chartMapa" class="mb-5">
    <!--MODAL-->
    <Modal
      :type="typeModal"
      :id="idModal"
      :pais="paisModal"
      :euros="eurosModal"
      :fichajes="fichajesModal"
    />

    <!--SELECTOR-->
    <v-select
      class="style-chooserMapa"
      :options="possibleKeys"
      :value="selectedKey[0]"
      @input="setSelected"
    ></v-select>

    <!--GRÁFICO-->
    <ResponsiveChoropleth
      class="bar mt-4"
      v-bind="chartCommonProps"
      :keys="selectedKey"
      :style="{ height: height + 'px' }"
    />
  </div>
</template>

<script>
import Data from "@/api/data.json";
import WorldCountires from "@/api/worldCountries.json";

/* eslint-disable no-unused-vars */
import React from "react";
import * as bootstrap from "bootstrap";

import { ResponsiveChoropleth } from "@nivo/geo";

import Modal from "./Modal";

export default {
  name: "Chart",
  components: {
    ResponsiveChoropleth,
    Modal,
  },
  props: {
    type: String,
    dataFichajes: Array,
    dataVentas: Array,
    data: Array,
    height: Number,
  },
  created() {
    this.idModal = `modalMapa${this.type}`;
    //console.log("DATOS INICIALES", Data);

    const topValueFichajes = this.dataFichajes.reduce(function (bigger, pais) {
      return (bigger.Fichajes || 0) > pais.Fichajes ? bigger : pais;
    }, {});

    const topValueVentas = this.dataVentas.reduce(function (bigger, pais) {
      return (bigger.Fichajes || 0) > pais.Fichajes ? bigger : pais;
    }, {});

    this.topValueFichajes = topValueFichajes.Fichajes;
    this.topValueVentas = topValueVentas.Ventas;
    this.chartCommonProps.domain[1] = topValueFichajes.Fichajes;

    this.chartCommonProps.data = this.orderBy(
      this.dataFichajes,
      "Fichajes",
      "asc"
    );
  },
  data() {
    return {
      idModal: "",
      fichajesModal: [],
      topValueFichajes: 0,
      topValueVentas: 0,
      typeModal: "",
      paisModal: "",
      eurosModal: 0,
      selectedKey: ["Fichajes"],
      possibleKeys: ["Fichajes", "Ventas"],
      chartCommonProps: {
        //BASE
        data: {},
        features: WorldCountires,
        //width: 100,
        //height: 100,
        margin: { top: 25, right: 25, bottom: 25, left: 0 },
        groupMode: "goruped",
        layout: "vertical",
        valueScale: { type: "linear" },
        indexScale: { type: "band", round: true },
        reverse: false,
        minValue: "auto",
        maxValue: "auto",
        padding: 0.2,
        innerPadding: 0,
        domain: [0, 17],
        projectionType: "naturalEarth1",
        projectionScale: 800,
        projectionTranslation: [0.2, 1.9],
        projectionRotation: [-3, 0, 0],
        enableGraticule: true,
        graticuleLineColor: "#dddddd",

        //STYLE
        theme: {
          background: "#ffffff",
          textColor: "#333333",
          fontSize: 14,
        },
        unknownColor: "#ffffff",
        colors: "YlOrRd",
        colorBy: "id",
        borderWidth: 1,
        borderColor: "#171e32e6",

        //CUSTOMIZATION
        layers: ["graticule", "features"],

        //LABELS
        enableLabel: true,
        label: this.label,
        labelSkipWidth: 14,
        labelSkipHeight: 14,
        labelTextColor: "#fff",

        //GRID AND AXES
        enableGridX: false,
        //gridXValues: 1,
        enableGridY: false,
        //gridYValues: 2,
        axisTop: null,
        axisRight: null,
        axisBottom: {
          legend: "",
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: 0,
        },
        axisLeft: null,

        //INTERACTIVITY
        isInteractive: true,
        // eslint-disable-next-line no-undef
        tooltip: this.tooltip,
        onClick: this.onClick,

        //LEGENDS
        legends: [
          {
            anchor: "top-left",
            direction: "column",
            justify: false,
            translateX: 17,
            translateY: 52,
            itemWidth: 100,
            itemHeight: 20,
            itemsSpacing: 4,
            symbolSize: 20,
            itemDirection: "left-to-right",
            itemTextColor: "#777",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000",
                  itemBackground: "#f7fafb",
                },
              },
            ],
          },
        ],

        //MOTION
        animate: true,
        motionConfig: "wobbly",

        //ACCESSIBILITY
        isFocusable: false,

        //MARKERS
        markers: [
          {
            axis: "y",
            value: 4.5,
            lineStyle: { stroke: "rgba(0, 0, 0, .35)", strokeWidth: 1 },
            legend: "Promedio 4.5",
            legendOrientation: "horizontal",
            legendPosition: "top-left",
          },
        ],

        //ANNOTATIONS
        annotations: [
          {
            type: "circle",
            match: { key: "Fichajes.2019" },
            noteX: 0,
            noteY: -40,
            offset: 1,
            noteTextOffset: -1,
            noteWidth: 1,
            note: "2019. El año con más fichajes",
            size: 60,
          },
          {
            type: "circle",
            match: { key: "Millones de €.2019" },
            noteX: 0,
            noteY: -40,
            offset: 1,
            noteTextOffset: -1,
            noteWidth: 1,
            note: "2019. El año con más gasto",
            size: 60,
          },
        ],
      },
    };
  },
  methods: {
    createNewArray: function () {
      //CREACIÓN NUEVO ARRAY
      const traspasosPorPais = [];
      //PRIMER PARSEADO PARA OBTENER FICHAJES
      Data.forEach((elem) => {
        const existeCountry = traspasosPorPais.find((el, index) => {
          if (el.Pais === elem.APais) {
            traspasosPorPais[index].Fichajes++;
            traspasosPorPais[index]["Millones de € Fichajes"] =
              traspasosPorPais[index]["Millones de € Fichajes"] + elem.Euros;
            return true;
          }
          return false;
        });
        if (!existeCountry) {
          traspasosPorPais.push({
            Pais: elem.APais,
            PaisISO: elem.APaisISO2.toUpperCase(),
            Fichajes: 1,
            Ventas: 0,
            "Millones de € Fichajes": elem.Euros,
            "Millones de € Ventas": 0,
          });
        }
      });
      //SEGUNDO PARSEADO PARA OBTENER VENTAS
      Data.forEach((elem) => {
        const existeCountry = traspasosPorPais.find((el, index) => {
          if (el.Pais === elem.DePais) {
            traspasosPorPais[index].Ventas++;
            traspasosPorPais[index]["Millones de € Ventas"] =
              traspasosPorPais[index]["Millones de € Ventas"] + elem.Euros;
            return true;
          }
          return false;
        });
        if (!existeCountry) {
          traspasosPorPais.push({
            Pais: elem.DePais,
            PaisISO: elem.DePaisISO2.toUpperCase(),
            Fichajes: 0,
            Ventas: 1,
            "Millones de € Fichajes": 0,
            "Millones de € Ventas": elem.Euros,
          });
        }
      });
      //console.log("NUEVOS");
      console.log(traspasosPorPais);
      return traspasosPorPais;
    },
    normalizeNumbers: function (array) {
      //NORMALIZACIÓN
      const normalized = array.map((anyo) => {
        return {
          ...anyo,
          "Millones de €":
            Math.round((anyo["Millones de €"] + Number.EPSILON) * 100) / 100,
        };
      });
      //console.log("Datos Normalizados");
      //console.log(normalized);
      return normalized;
    },
    orderBy: function (array, key) {
      //ORDEN
      array.sort(function (a, b) {
        if (a[key] > b[key]) {
          return 1;
        }
        if (a[key] < b[key]) {
          return -1;
        }
        return 0;
      });
      //console.log("Datos Ordenados");
      //console.log(array);
      return array;
    },
    addEmptyValues: function (array, key) {
      //AÑADIR VACÍOS
      let value = array[0][key];
      const lastValue = array[array.length - 1][key];
      while (value < lastValue) {
        value++;
        const existeValue = array.find((el) => el[key] === value);
        if (!existeValue) {
          const obj = {
            Fichajes: 0,
            "Millones de €": 0,
          };
          obj[key] = value;
          array.push(obj);
        }
      }
      //console.log("Datos Con Vacíos");
      //console.log(array);
      return array;
    },
    clean: function (array) {
      //AÑADIR DETALLES
      const cleaned = array.map((elem) => {
        return {
          id: elem.PaisISO,
          value: elem.Fichajes,
        };
      });
      //console.log("Datos Con Detalles");
      console.log(cleaned);
      return cleaned;
    },
    getAverage: function (array, key, includeEmptyValues) {
      //OBETENER MEDIA
      const count = includeEmptyValues
        ? array.length
        : array.reduce((acc, el) => (el[key] !== 0 ? acc + 1 : acc), 0);
      //console.log(count);
      const total = array.reduce((acc, el) => acc + el[key], 0);
      const avg = total / count;
      //console.log("Media de " + key);
      //console.log(avg);
      return avg;
    },
    label: function (e) {
      //console.log(e);
      return `${e.value}`;
    },
    tooltip: function (e) {
      //console.log(e);
      if (e.feature.value > 0) {
        const number =
          this.selectedKey[0] === "Fichajes"
            ? e.feature.data.Fichajes
            : e.feature.data.Ventas;
        const string =
          this.selectedKey[0] === "Fichajes" ? "Fichajes" : "Ventas";
        return (
          <div
            className="card"
            style={{ background: "#171e32", color: "white" }}
          >
            <div className="card-header">
              <strong>{e.feature.data.Pais} </strong>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <strong> {number} </strong> {string}
              </li>
            </ul>
          </div>
        );
      } else {
        return <span></span>;
      }
    },
    onClick: function (e) {
      console.log(e);
      if (this.selectedKey[0] === "Fichajes") {
        this.fichajesModal = e.data["Jugadores Fichados"];
        this.paisModal = e.data.Pais;
        this.eurosModal = e.data["Millones de € Fichajes"];
      } else {
        this.fichajesModal = e.data["Jugadores Vendidos"];
        this.paisModal = e.data.Pais;
        this.eurosModal = e.data["Millones de € Ventas"];
      }
      this.typeModal = this.selectedKey[0];
      const modal = new bootstrap.Modal(document.getElementById(this.idModal));
      modal.show();
      return;
    },
    setSelected: function (value) {
      if (value !== null) {
        this.selectedKey = [value];
        //console.log(value);
        if (value === "Fichajes") {
          this.chartCommonProps.domain[1] = this.topValueFichajes;
          this.chartCommonProps.data = this.orderBy(
            this.dataFichajes,
            "Fichajes",
            "asc"
          );
        } else {
          console.log(this.dataVentas);
          this.chartCommonProps.domain[1] = this.topValueVentas;
          this.chartCommonProps.data = this.orderBy(
            this.dataVentas,
            "Ventas",
            "asc"
          );
        }
      }
      return;
    },
  },
};
</script>

<style scoped>
#chartMapa {
  text-align: center;
  margin-top: 60px;
}

.bar {
  height: 400px;
  width: 100%;
  cursor: pointer;
}
</style>

<style>
.style-chooserMapa .vs__search::placeholder,
.style-chooserMapa .vs__dropdown-toggle,
.style-chooserMapa .vs__dropdown-menu {
  background: #f1f1f1;
  border: none;
  color: var(--primary);
  text-transform: uppercase;
  width: 25%;
  padding: 0.5rem;
}

.style-chooserMapa .vs__selected,
.style-chooserMapa .vs__dropdown-option {
  font-size: 1.2rem;
  color: var(--primary) !important;
  fill: white;
}

.style-chooserMapa .vs__clear {
  fill: #f1f1f1;
}

.style-chooserMapa .vs__open-indicator {
  fill: var(--primary) !important;
}

.style-chooserMapa .vs__open-indicator,
.style-chooserMapa .vs__selected,
.style-chooserMapa .vs__dropdown-option {
  color: #fff;
  fill: white;
}

.style-chooserMapa .vs__dropdown-option--highlight {
  background: #fff;
  color: var(--primary);
}
</style>
