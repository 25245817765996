<template>
  <div>
    <div class="mb-5 collapse paisCollapse" id="paisCollapse">
      <ChartPais />
      <ChartMapa />
    </div>
    <a
      class="collapsed paisCollapse"
      data-bs-toggle="collapse"
      href="#paisCollapse"
      role="button"
      aria-expanded="false"
      aria-controls="paisCollapse"
    >
    </a>
  </div>
</template>

<script>
import ChartPais from "@/components/charts/pais/chartPais/ChartPais.vue";
import ChartMapa from "@/components/charts/pais/chartMapa/ChartMapa.vue";

export default {
  name: "PaisWrapper",
  components: {
    ChartPais,
    ChartMapa,
  },
};
</script>

<style scoped>
.collapse {
  position: relative;
  /* For ...'s content absolute positioning */
  display: block !important;
  overflow: hidden;
}

a {
  color: rgb(87, 81, 81) !important;
  font-weight: 600;
  text-decoration: none;
}

.collapse:before {
  content: " ...";
  position: absolute;
  right: 0;
  bottom: 0;
}

.collapse.show {
  height: auto;
  /* You need to reset the height when not collapsed */
}

.collapse.show:before {
  display: none;
  /* Of course you don't want to display ... */
}

a.collapsed:after {
  content: "+ Ver más";
}

a:not(.collapsed):after {
  content: "- Ver menos";
}

a.collapsed.equiposMasFichajesCollapse:after,
a.collapsed.equiposMasGastadoCollapse:after,
a.collapsed.equiposMasVendidosCollapse:after,
a.collapsed.equiposMasIngresosCollapse:after,
a.collapsed.origenCollapse:after {
  content: "+ Ampliar tabla";
}

a:not(.collapsed).equiposMasFichajesCollapse:after,
a:not(.collapsed).equiposMasGastadoCollapse:after,
a:not(.collapsed).equiposMasVendidosCollapse:after,
a:not(.collapsed).equiposMasIngresosCollapse:after,
a:not(.collapsed).origenCollapse:after {
  content: "- Cerrar tabla";
}

.paisCollapse {
  height: 60rem;
  /* [NUM_OF_LINES] x [LINE_HEIGHT] */
}
</style>