<template>
  <div id="chartGridWrapper" class="mb-5">
    <div class="d-flex">
      <div class="w-100 mt-4">
        <h3 class="display-5 mt-5 mb-5 text-center">Posición x País</h3>
        <div class="d-flex mt-5">
          <div class="w-100">
            <h3 class="display-5 mb-4 text-center">Fichajes</h3>
            <Chart
              v-if="dataFichajes"
              type="fichajes"
              :data="dataFichajes"
              :avg="avgFichajes"
              :avgMillones="avgMillonesFichajes"
              :avgMillonesX="avgMillonesFichajesX"
              :height="500"
            />
          </div>
          <div class="w-100">
            <h3 class="display-5 mb-4 text-center">Ventas</h3>
            <Chart
              v-if="dataVentas"
              type="ventas"
              :data="dataVentas"
              :avg="avgFichajes"
              :avgMillones="avgMillonesFichajes"
              :avgMillonesX="avgMillonesFichajesX"
              :height="500"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Data from "@/api/data.json";

import Chart from "./Chart";

export default {
  name: "ChartGrid",
  components: {
    Chart,
  },

  data() {
    return {
      dataFichajes: [],
      dataVentas: [],
      dataFichajesVsVentas: [],
      avgFichajes: 0,
      avgVentas: 0,
      avgMillonesFichajes: 0,
      avgMillonesVentas: 0,
      avgMillonesFichajesX: 0,
      avgMillonesVentasX: 0,
    };
  },

  created() {
    //console.log("DATOS INICIALES", Data);

    const fichajesPorPais = this.createNewArray("Fichajes");
    const ventasPorPais = this.createNewArray("Ventas");

    this.dataFichajes = fichajesPorPais;
    this.dataVentas = ventasPorPais;
  },
  methods: {
    createNewArray: function (type) {
      //CREACIÓN NUEVO ARRAY
      const traspasosPorPais = [];
      if (type === "Fichajes") {
        //PRIMER PARSEADO PARA OBTENER FICHAJES
        Data.forEach((elem) => {
          const existePais = traspasosPorPais.find((el, index) => {
            if (el.Pais === elem.APais) {
              switch (elem.Posicion) {
                case "Portero":
                  traspasosPorPais[index].Portero++;
                  break;
                case "Defensa":
                  traspasosPorPais[index].Defensa++;
                  break;
                case "Mediocentro":
                  traspasosPorPais[index].Mediocentro++;
                  break;
                case "Mediapunta":
                  traspasosPorPais[index].Mediapunta++;
                  break;
                case "Delantero":
                  traspasosPorPais[index].Delantero++;
                  break;
                default:
                  break;
              }
              return true;
            }
            return false;
          });
          if (!existePais) {
            const obj = {
              Pais: elem.APais,
              PaisISO: elem.APaisISO,
              Portero: 0,
              Defensa: 0,
              Mediocentro: 0,
              Mediapunta: 0,
              Delantero: 0,
            };
            switch (elem.Posicion) {
              case "Portero":
                obj.Portero++;
                break;
              case "Defensa":
                obj.Defensa++;
                break;
              case "Mediocentro":
                obj.Mediocentro++;
                break;
              case "Mediapunta":
                obj.Mediapunta++;
                break;
              case "Delantero":
                obj.Delantero++;
                break;
              default:
                break;
            }
            traspasosPorPais.push(obj);
          }
        });
      } else {
        //SEGUNDO PARSEADO PARA OBTENER VENTAS
        Data.forEach((elem) => {
          const existePais = traspasosPorPais.find((el, index) => {
            if (el.Pais === elem.DePais) {
              switch (elem.Posicion) {
                case "Portero":
                  traspasosPorPais[index].Portero++;
                  break;
                case "Defensa":
                  traspasosPorPais[index].Defensa++;
                  break;
                case "Mediocentro":
                  traspasosPorPais[index].Mediocentro++;
                  break;
                case "Mediapunta":
                  traspasosPorPais[index].Mediapunta++;
                  break;
                case "Delantero":
                  traspasosPorPais[index].Delantero++;
                  break;
                default:
                  break;
              }
              return true;
            }
            return false;
          });
          if (!existePais) {
            const obj = {
              Pais: elem.DePais,
              PaisISO: elem.DePaisISO,
              Portero: 0,
              Defensa: 0,
              Mediocentro: 0,
              Mediapunta: 0,
              Delantero: 0,
            };
            switch (elem.Posicion) {
              case "Portero":
                obj.Portero++;
                break;
              case "Defensa":
                obj.Defensa++;
                break;
              case "Mediocentro":
                obj.Mediocentro++;
                break;
              case "Mediapunta":
                obj.Mediapunta++;
                break;
              case "Delantero":
                obj.Delantero++;
                break;
              default:
                break;
            }
            traspasosPorPais.push(obj);
          }
        });
      }

      //console.log("NUEVOS");
      //console.log(traspasosPorPais);
      return traspasosPorPais;
    },
    normalizeNumbers: function (array) {
      //NORMALIZACIÓN
      const normalized = array.map((posicion) => {
        return {
          ...posicion,
          "Millones de € Fichajes":
            Math.round(
              (posicion["Millones de € Fichajes"] + Number.EPSILON) * 100
            ) / 100,
          "Millones de € Ventas":
            Math.round(
              (posicion["Millones de € Ventas"] + Number.EPSILON) * 100
            ) / 100,
        };
      });
      //console.log("Datos Normalizados");
      //console.log(normalized);
      return normalized;
    },
    orderBy: function (array, key) {
      //ORDEN
      array.sort(function (a, b) {
        if (a[key] > b[key]) {
          return 1;
        }
        if (a[key] < b[key]) {
          return -1;
        }
        return 0;
      });
      //console.log("Datos Ordenados");
      //console.log(array);
      return array;
    },
    addAverage: function (array) {
      //AÑADIR MEDIA
      const average = array.map((posicion) => {
        const millXfichaje =
          posicion.Fichajes > 0
            ? posicion["Millones de € Fichajes"] / posicion.Fichajes
            : 0;
        const millXventa =
          posicion.Ventas > 0
            ? posicion["Millones de € Ventas"] / posicion.Ventas
            : 0;
        return {
          ...posicion,
          "Millones de € x Fichaje":
            Math.round((millXfichaje + Number.EPSILON) * 100) / 100,
          "Millones de € x Venta":
            Math.round((millXventa + Number.EPSILON) * 100) / 100,
        };
      });
      //console.log("Datos Average");
      //console.log(average);
      return average;
    },
    addDetails: function (array, myKey, dataKey, newKey) {
      //AÑADIR DETALLES
      const detailed = array.map((elem) => {
        const posicion = elem[myKey];
        //console.log(posicion);
        const filtered = Data.filter((el) => el[dataKey] === posicion);
        const final = {
          ...elem,
        };
        final[newKey] = filtered;
        return final;
      });
      //console.log("Datos Con Detalles");
      //console.log(detailed);
      return detailed;
    },
    getAverage: function (array, key, includeEmptyValues) {
      //OBETENER MEDIA
      const count = includeEmptyValues
        ? array.length
        : array.reduce((acc, el) => (el[key] > 0 ? acc + 1 : acc), 0);
      //console.log(key);
      //console.log(array);
      //console.log(count);
      const total = array.reduce(
        (acc, el) => (el[key] > 0 ? acc + el[key] : acc),
        0
      );
      //console.log(total);
      const avg = total / count;
      //console.log("Media de " + key);
      //console.log(avg);
      return avg;
    },
    adaptForFichajes: function (array) {
      //AÑADIR DETALLES
      let adapted = array.filter((el) => el.Fichajes > 0);
      //console.log("Datos adaptados para fichajes");
      //console.log(adapted);
      return adapted;
    },
    adaptForVentas: function (array) {
      //AÑADIR DETALLES
      let adapted = array.filter((el) => el.Ventas > 0);
      //console.log("Datos adaptados para ventas");
      //console.log(adapted);
      return adapted;
    },
  },
};
</script>
