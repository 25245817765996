<template>
  <div id="chartMapaWrapper" class="mb-5">
    <div class="d-flex">
      <div class="w-100 mt-5">
        <h3 class="display-5 mt-4 mb-5 text-center">Mapa</h3>
        <Chart
          v-if="dataFichajes"
          type="fichajes"
          :dataFichajes="dataFichajes"
          :dataVentas="dataVentas"
          :height="450"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Data from "@/api/data.json";

import Chart from "./Chart";

export default {
  name: "ChartMapa",
  components: {
    Chart,
  },

  data() {
    return {
      dataFichajes: [],
      dataVentas: [],
    };
  },

  created() {
    //console.log("DATOS INICIALES", Data);

    const traspasosPorAnyo = this.createNewArray();
    const normalized = this.normalizeNumbers(traspasosPorAnyo);
    const detailed = this.addDetails(
      normalized,
      "Pais",
      "APais",
      "Jugadores Fichados"
    );
    const detailed2 = this.addDetails(
      detailed,
      "Pais",
      "DePais",
      "Jugadores Vendidos"
    );
    const ordered1 = this.orderBy(detailed2, "PaisISO");

    const adaptedFichajes = this.adaptForFichajes(ordered1);
    const finalFichajes = this.clean(adaptedFichajes, "Fichajes");

    const adaptedVentas = this.adaptForVentas(ordered1);
    const finalVentas = this.clean(adaptedVentas, "Ventas");

    this.dataFichajes = finalFichajes;
    this.dataVentas = finalVentas;
  },
  methods: {
    createNewArray: function () {
      //CREACIÓN NUEVO ARRAY
      const traspasosPorPais = [];
      //PRIMER PARSEADO PARA OBTENER FICHAJES
      Data.forEach((elem) => {
        const existeCountry = traspasosPorPais.find((el, index) => {
          if (el.Pais === elem.APais) {
            traspasosPorPais[index].Fichajes++;
            traspasosPorPais[index]["Millones de € Fichajes"] =
              traspasosPorPais[index]["Millones de € Fichajes"] + elem.Euros;
            return true;
          }
          return false;
        });
        if (!existeCountry) {
          traspasosPorPais.push({
            Pais: elem.APais,
            PaisISO: elem.APaisISO2.toUpperCase(),
            Fichajes: 1,
            Ventas: 0,
            "Millones de € Fichajes": elem.Euros,
            "Millones de € Ventas": 0,
          });
        }
      });
      //SEGUNDO PARSEADO PARA OBTENER VENTAS
      Data.forEach((elem) => {
        const existeCountry = traspasosPorPais.find((el, index) => {
          if (el.Pais === elem.DePais) {
            traspasosPorPais[index].Ventas++;
            traspasosPorPais[index]["Millones de € Ventas"] =
              traspasosPorPais[index]["Millones de € Ventas"] + elem.Euros;
            return true;
          }
          return false;
        });
        if (!existeCountry) {
          traspasosPorPais.push({
            Pais: elem.DePais,
            PaisISO: elem.DePaisISO2.toUpperCase(),
            Fichajes: 0,
            Ventas: 1,
            "Millones de € Fichajes": 0,
            "Millones de € Ventas": elem.Euros,
          });
        }
      });
      //console.log("NUEVOS");
      //console.log(traspasosPorPais);
      return traspasosPorPais;
    },
    normalizeNumbers: function (array) {
      //NORMALIZACIÓN
      const normalized = array.map((pais) => {
        return {
          ...pais,
          "Millones de € Fichajes":
            Math.round(
              (pais["Millones de € Fichajes"] + Number.EPSILON) * 100
            ) / 100,
          "Millones de € Ventas":
            Math.round((pais["Millones de € Ventas"] + Number.EPSILON) * 100) /
            100,
        };
      });
      //console.log("Datos Normalizados");
      //console.log(normalized);
      return normalized;
    },
    orderBy: function (array, key) {
      //ORDEN
      array.sort(function (a, b) {
        if (a[key] > b[key]) {
          return 1;
        }
        if (a[key] < b[key]) {
          return -1;
        }
        return 0;
      });
      //console.log("Datos Ordenados");
      //console.log(array);
      return array;
    },
    addAverage: function (array) {
      //AÑADIR MEDIA
      const average = array.map((pais) => {
        const millXfichaje =
          pais.Fichajes > 0
            ? pais["Millones de € Fichajes"] / pais.Fichajes
            : 0;
        const millXventa =
          pais.Ventas > 0 ? pais["Millones de € Ventas"] / pais.Ventas : 0;
        return {
          ...pais,
          "Millones de € x Fichaje":
            Math.round((millXfichaje + Number.EPSILON) * 100) / 100,
          "Millones de € x Venta":
            Math.round((millXventa + Number.EPSILON) * 100) / 100,
        };
      });
      //console.log("Datos Average");
      //console.log(average);
      return average;
    },
    addDetails: function (array, myKey, dataKey, newKey) {
      //AÑADIR DETALLES
      const detailed = array.map((elem) => {
        const pais = elem[myKey];
        //console.log(pais);
        const filtered = Data.filter((el) => el[dataKey] === pais);
        const final = {
          ...elem,
        };
        final[newKey] = filtered;
        return final;
      });
      //console.log("Datos Con Detalles");
      //console.log(detailed);
      return detailed;
    },
    clean: function (array, type) {
      //AÑADIR DETALLES
      //console.log(array);
      const cleaned = array.map((elem) => {
        const element = {
          ...elem,
          id: elem.PaisISO,
        };
        if (type === "Fichajes") {
          element.value = elem.Fichajes;
        } else {
          element.value = elem.Ventas;
        }
        return element;
      });
      //console.log("Datos Con Detalles");
      //console.log(cleaned);
      return cleaned;
    },
    getAverage: function (array, key, includeEmptyValues) {
      //OBETENER MEDIA
      const count = includeEmptyValues
        ? array.length
        : array.reduce((acc, el) => (el[key] > 0 ? acc + 1 : acc), 0);
      //console.log(key);
      //console.log(array);
      //console.log(count);
      const total = array.reduce(
        (acc, el) => (el[key] > 0 ? acc + el[key] : acc),
        0
      );
      //console.log(total);
      const avg = total / count;
      //console.log("Media de " + key);
      //console.log(avg);
      return avg;
    },
    adaptForFichajes: function (array) {
      //AÑADIR DETALLES
      let adapted = array.filter((el) => el.Fichajes > 0);
      //console.log("Datos adaptados para fichajes");
      //console.log(adapted);
      return adapted;
    },
    adaptForVentas: function (array) {
      //AÑADIR DETALLES
      let adapted = array.filter((el) => el.Ventas > 0);
      //console.log("Datos adaptados para ventas");
      //console.log(adapted);
      return adapted;
    },
  },
};
</script>
