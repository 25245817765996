<template>
  <div id="chartAnyoTraspaso" class="mb-5">
    <!--MODAL-->
    <Modal
      :id="idModal"
      :anyo="anyoModal"
      :euros="eurosModal"
      :fichajes="fichajesModal"
    />

    <!--SELECTOR-->
    <v-select
      class="style-chooserAnyoTraspaso"
      :options="possibleKeys"
      :value="selectedKey[0]"
      @input="setSelected"
    ></v-select>

    <!--GRÁFICO-->
    <ResponsiveBar class="bar" v-bind="chartCommonProps" :keys="selectedKey" />
  </div>
</template>

<script>
import Data from "@/api/data.json";

/* eslint-disable no-unused-vars */
import React from "react";
import * as bootstrap from "bootstrap";

import { ResponsiveBar } from "@nivo/bar";

import Modal from "./Modal";

export default {
  name: "Chart",
  components: {
    ResponsiveBar,
    Modal,
  },
  created() {
    //console.log("DATOS INICIALES", Data);

    const traspasosPorAnyo = this.createNewArray();
    const normalized = this.normalizeNumbers(traspasosPorAnyo);
    const ordered1 = this.orderBy(normalized, "Anyo");
    const withEmpty = this.addEmptyValues(ordered1, "Anyo");
    const average = this.addAverage(withEmpty, "Fichajes");
    const ordered2 = this.orderBy(average, "Anyo");
    const final = this.addDetails(ordered2, "Anyo");

    const avgFichajes =
      Math.round(
        (this.getAverage(final, "Fichajes", false) + Number.EPSILON) * 100
      ) / 100;
    const avgMillones =
      Math.round(
        (this.getAverage(final, "Millones de €", false) + Number.EPSILON) * 100
      ) / 100;

    this.avgFichajes = avgFichajes;
    this.avgMillones = avgMillones;

    this.chartCommonProps.markers[0].value = avgFichajes;
    this.chartCommonProps.markers[0].legend = `${this.avgFichajes} fichajes por año`;

    this.chartCommonProps.data = final;
  },
  data() {
    return {
      idModal: "modalAnyoTraspaso",
      fichajesModal: [],
      anyoModal: 0,
      eurosModal: 0,
      selectedKey: ["Fichajes"],
      possibleKeys: ["Fichajes", "Millones de €", "Millones de € x Fichaje"],
      avgFichajes: 0,
      avgMillones: 0,
      chartCommonProps: {
        //BASE
        data: {},
        indexBy: "Anyo",
        groupMode: "goruped",
        layout: "vertical",
        valueScale: { type: "linear" },
        indexScale: { type: "band", round: true },
        reverse: false,
        minValue: "auto",
        maxValue: "auto",
        padding: 0.2,
        innerPadding: 0,
        //width: 100,
        height: 400,
        margin: { top: 25, right: 25, bottom: 50, left: 0 },

        //STYLE
        theme: {
          background: "#ffffff",
          textColor: "#333333",
          fontSize: 14,
        },
        colors: { scheme: "greys" },
        colorBy: "id",
        borderRadius: 5,
        borderWidth: 2,
        borderColor: "#171e32e6",
        defs: [
          {
            id: "grey",
            type: "patternDots",
            background: "#171e32",
            color: "#171e32",
            size: 20,
            padding: 1,
          },
        ],
        fill: [
          {
            match: {
              id: "Fichajes",
            },
            id: "grey",
          },
          {
            match: {
              id: "Millones de €",
            },
            id: "grey",
          },
          {
            match: {
              id: "Millones de € x Fichaje",
            },
            id: "grey",
          },
        ],

        //CUSTOMIZATION
        layers: ["grid", "axes", "bars", "markers", "legends", "annotations"],

        //LABELS
        enableLabel: true,
        label: this.label,
        labelSkipWidth: 14,
        labelSkipHeight: 14,
        labelTextColor: "#fff",

        //GRID AND AXES
        enableGridX: false,
        //gridXValues: 1,
        enableGridY: false,
        //gridYValues: 2,
        axisTop: null,
        axisRight: null,
        axisBottom: {
          legend: "",
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: 0,
        },
        axisLeft: null,

        //INTERACTIVITY
        isInteractive: true,
        tooltip: this.tooltip,
        onClick: this.onClick,

        //LEGENDS
        legends: [],

        //MOTION
        animate: true,
        motionConfig: "wobbly",

        //ACCESSIBILITY
        isFocusable: false,

        //MARKERS
        markers: [
          {
            axis: "y",
            value: 4.5,
            lineStyle: { stroke: "rgba(0, 0, 0, .35)", strokeWidth: 1 },
            legend: "Promedio 4.5",
            legendOrientation: "horizontal",
            legendPosition: "top-left",
          },
        ],

        //ANNOTATIONS
        annotations: [
          {
            type: "circle",
            match: { key: "Fichajes.2019" },
            noteX: 0,
            noteY: -40,
            offset: 1,
            noteTextOffset: -1,
            noteWidth: 1,
            note: "2019. El año con más fichajes",
            size: 60,
          },
          {
            type: "circle",
            match: { key: "Millones de €.2019" },
            noteX: 0,
            noteY: -40,
            offset: 1,
            noteTextOffset: -1,
            noteWidth: 1,
            note: "2019. El año con más gasto",
            size: 60,
          },
        ],
      },
    };
  },
  methods: {
    createNewArray: function () {
      //CREACIÓN NUEVO ARRAY
      const traspasosPorAnyo = [];
      Data.forEach((elem) => {
        const existeAnyo = traspasosPorAnyo.find((el, index) => {
          if (el.Anyo === elem.Anyo) {
            traspasosPorAnyo[index].Fichajes++;
            traspasosPorAnyo[index]["Millones de €"] =
              traspasosPorAnyo[index]["Millones de €"] + elem.Euros;
            return true;
          }
          return false;
        });
        if (!existeAnyo) {
          traspasosPorAnyo.push({
            Anyo: elem.Anyo,
            Fichajes: 1,
            "Millones de €": elem.Euros,
          });
        }
      });
      //console.log("NUEVOS");
      //console.log(traspasosPorAnyo);
      return traspasosPorAnyo;
    },
    normalizeNumbers: function (array) {
      //NORMALIZACIÓN
      const normalized = array.map((anyo) => {
        return {
          ...anyo,
          "Millones de €":
            Math.round((anyo["Millones de €"] + Number.EPSILON) * 100) / 100,
        };
      });
      //console.log("Datos Normalizados");
      //console.log(normalized);
      return normalized;
    },
    orderBy: function (array, key) {
      //ORDEN
      array.sort(function (a, b) {
        if (a[key] > b[key]) {
          return 1;
        }
        if (a[key] < b[key]) {
          return -1;
        }
        return 0;
      });
      //console.log("Datos Ordenados");
      //console.log(array);
      return array;
    },
    addEmptyValues: function (array, key) {
      //AÑADIR VACÍOS
      let value = array[0][key];
      const lastValue = array[array.length - 1][key];
      while (value < lastValue) {
        value++;
        const existeValue = array.find((el) => el[key] === value);
        if (!existeValue) {
          const obj = {
            Fichajes: 0,
            "Millones de €": 0,
          };
          obj[key] = value;
          array.push(obj);
        }
      }
      //console.log("Datos Con Vacíos");
      //console.log(array);
      return array;
    },
    addAverage: function (array) {
      //AÑADIR MEDIA
      const average = array.map((anyo) => {
        const millXfichaje =
          anyo.Fichajes > 0 ? anyo["Millones de €"] / anyo.Fichajes : 0;
        return {
          ...anyo,
          "Millones de € x Fichaje":
            Math.round((millXfichaje + Number.EPSILON) * 100) / 100,
        };
      });
      console.log("Datos Average");
      console.log(average);
      return average;
    },
    addDetails: function (array, key) {
      //AÑADIR DETALLES
      const detailed = array.map((elem) => {
        const anyo = elem[key];
        //console.log(anyo);
        const jugadores = Data.filter((el) => el[key] === anyo);
        return {
          ...elem,
          Jugadores: jugadores,
        };
      });
      //console.log("Datos Con Detalles");
      //console.log(detailed);
      return detailed;
    },
    getAverage: function (array, key, includeEmptyValues) {
      //OBETENER MEDIA
      const count = includeEmptyValues
        ? array.length
        : array.reduce((acc, el) => (el[key] !== 0 ? acc + 1 : acc), 0);
      //console.log(count);
      const total = array.reduce((acc, el) => acc + el[key], 0);
      const avg = total / count;
      //console.log("Media de " + key);
      //console.log(avg);
      return avg;
    },
    label: function (e) {
      //console.log(e);
      return `${e.value}`;
    },
    tooltip: function (e) {
      //console.log(e);
      return (
        <div className="card" style={{ background: "#171e32", color: "white" }}>
          <div className="card-header">
            <strong>Año {e.data.Anyo} </strong>
          </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <strong> {e.data.Fichajes} </strong> Fichajes
            </li>
            <li className="list-group-item">
              <strong> {e.data["Millones de €"]} </strong> Millones de €
            </li>
          </ul>
        </div>
      );
    },
    onClick: function (e) {
      //console.log(e);
      this.fichajesModal = e.data.Jugadores;
      this.anyoModal = e.data.Anyo;
      this.eurosModal = e.data["Millones de €"];
      const modal = new bootstrap.Modal(document.getElementById(this.idModal));
      modal.show();
      return;
    },
    setSelected: function (value) {
      if (value !== null) {
        this.selectedKey = [value];
        //console.log(value);
        if (value === "Fichajes") {
          this.chartCommonProps.markers[0].value = this.avgFichajes;
          this.chartCommonProps.markers[0].legend = `${this.avgFichajes} fichajes por año`;
        } else {
          this.chartCommonProps.markers[0].value = this.avgMillones;
          this.chartCommonProps.markers[0].legend = `${this.avgFichajes} mill de € por año`;
        }
      }
      return;
    },
  },
};
</script>

<style scoped>
#chartAnyoTraspaso {
  text-align: center;
  margin-top: 60px;
}

.bar {
  height: 400px;
  width: 100%;
  cursor: pointer;
}
</style>

<style>
.style-chooserAnyoTraspaso .vs__search::placeholder,
.style-chooserAnyoTraspaso .vs__dropdown-toggle,
.style-chooserAnyoTraspaso .vs__dropdown-menu {
  background: #f1f1f1;
  border: none;
  color: var(--primary);
  text-transform: uppercase;
  width: 30%;
  padding: 0.5rem;
}

.style-chooserAnyoTraspaso .vs__selected,
.style-chooserAnyoTraspaso .vs__dropdown-option {
  font-size: 1.2rem;
  color: var(--primary) !important;
  fill: white;
}

.style-chooserAnyoTraspaso .vs__clear {
  fill: #f1f1f1;
}

.style-chooserAnyoTraspaso .vs__open-indicator {
  fill: var(--primary) !important;
}

.style-chooserAnyoTraspaso .vs__open-indicator,
.style-chooserAnyoTraspaso .vs__selected,
.style-chooserAnyoTraspaso .vs__dropdown-option {
  color: #fff;
  fill: white;
}

.style-chooserAnyoTraspaso .vs__dropdown-option--highlight {
  background: #fff;
  color: var(--primary);
}
</style>
