<template>
  <nav class="navbar">
    <div class="container-fluid justify-content-between align-items-center">
      <span class="navbar-brand mb-0 h1 ms-2"
        >Infografía y visualización. Práctica.</span
      >
      <span class="navbar-brand mb-0 h1 me-2">Pepe Fernández Marín</span>
    </div>
  </nav>
</template>

<script>
export default {
  name: "HeaderFooter",
};
</script>

<style>
.navbar {
  min-height: 80px !important;
  background-color: var(--primary) !important;
}

.navbar-brand {
  color: white !important;
}

.navbar-brand:hover {
  color: white !important;
}
</style>
