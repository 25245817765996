<template>
  <div id="chartAnyoTraspasoWrapper" class="mb-5">
    <h2 class="display-3 mb-5 text-center">Por Año del traspaso</h2>
    <Chart />
    <div
      class="alert alert-warning d-flex align-items-start text-start mt-5"
      role="alert"
    >
      <p class="p-3">
        La mayor cantidad de fichajes en el top 50 se produjeron en 2019 (14),
        seguido del año 2018 (11). También es en esos años cuando más dinero se
        gastó en este tipo de fichajes, doblando al tercer año en gasto. Sin
        embargo, es en el año 2017 cuando más dinero se gastó por fichaje (108
        mill.), debido al fichaje de Neymar (222 mill.).
      </p>
    </div>
  </div>
</template>

<script>
import Chart from "./Chart";

export default {
  name: "ChartAnyoTraspaso",
  components: {
    Chart,
  },
};
</script>
