<template>
  <div id="chartPosicionWrapper" class="mb-4">
    <h2 class="display-3 mb-5 text-center">Por Posición</h2>
    <div class="d-flex">
      <div class="w-100 mt-5">
        <Chart
          v-if="dataFichajes"
          type="fichajes"
          :data="dataFichajes"
          :avg="avgFichajes"
          :avgMillones="avgMillonesFichajes"
          :avgMillonesX="avgMillonesFichajesX"
          :height="400"
        />
      </div>
    </div>
    <div
      class="alert alert-warning d-flex align-items-start text-start mt-5"
      role="alert"
    >
      <p class="p-3">
        La posición que más destaca es la de medio (20). También es en la que
        más dinero se ha invertido (1551 mill.). Sin embargo, la posición de
        mediapunta/extremo (135 mill.) es con mucha diferencia la más cara por
        fichaje.
      </p>
    </div>
  </div>
</template>

<script>
import Data from "@/api/data.json";

import Chart from "./Chart";

export default {
  name: "ChartPosicion",
  components: {
    Chart,
  },

  data() {
    return {
      dataFichajes: [],
      dataVentas: [],
      dataFichajesVsVentas: [],
      avgFichajes: 0,
      avgVentas: 0,
      avgMillonesFichajes: 0,
      avgMillonesVentas: 0,
      avgMillonesFichajesX: 0,
      avgMillonesVentasX: 0,
    };
  },

  created() {
    //console.log("DATOS INICIALES", Data);

    const traspasosPorPosicion = this.createNewArray();
    const normalized = this.normalizeNumbers(traspasosPorPosicion);
    const average = this.addAverage(normalized, "Fichajes");
    const final1 = this.addDetails(
      average,
      "Posicion",
      "Posicion",
      "Jugadores Fichados"
    );
    const final2 = this.addDetails(
      final1,
      "Posicion",
      "Posicion",
      "Jugadores Vendidos"
    );

    const avgFichajes =
      Math.round(
        (this.getAverage(final2, "Fichajes", false) + Number.EPSILON) * 100
      ) / 100;
    const avgVentas =
      Math.round(
        (this.getAverage(final2, "Ventas", false) + Number.EPSILON) * 100
      ) / 100;
    const avgMillonesFichajes =
      Math.round(
        (this.getAverage(final2, "Millones de € Fichajes", false) +
          Number.EPSILON) *
          100
      ) / 100;
    const avgMillonesVentas =
      Math.round(
        (this.getAverage(final2, "Millones de € Ventas", false) +
          Number.EPSILON) *
          100
      ) / 100;
    const avgMillonesFichajesX =
      Math.round(
        (this.getAverage(final2, "Millones de € x Fichaje", false) +
          Number.EPSILON) *
          100
      ) / 100;
    const avgMillonesVentasX =
      Math.round(
        (this.getAverage(final2, "Millones de € x Venta", false) +
          Number.EPSILON) *
          100
      ) / 100;

    this.avgFichajes = avgFichajes;
    this.avgVentas = avgVentas;
    this.avgMillonesFichajes = avgMillonesFichajes;
    this.avgMillonesVentas = avgMillonesVentas;
    this.avgMillonesFichajesX = avgMillonesFichajesX;
    this.avgMillonesVentasX = avgMillonesVentasX;

    const finalFichajes = this.adaptForFichajes(final2);
    const orderedFichajes = this.orderBy(finalFichajes, "Fichajes");
    const finalVentas = this.adaptForVentas(final2);
    const orderedVentas = this.orderBy(finalVentas, "Ventas");

    this.dataFichajes = orderedFichajes;
    this.dataVentas = orderedVentas;
    this.dataFichajesVsVentas = final2;
  },
  methods: {
    createNewArray: function () {
      //CREACIÓN NUEVO ARRAY
      const traspasosPorPosicion = [];
      //PRIMER PARSEADO PARA OBTENER FICHAJES
      Data.forEach((elem) => {
        const existePosicion = traspasosPorPosicion.find((el, index) => {
          if (el.Posicion === elem.Posicion) {
            traspasosPorPosicion[index].Fichajes++;
            traspasosPorPosicion[index]["Millones de € Fichajes"] =
              traspasosPorPosicion[index]["Millones de € Fichajes"] +
              elem.Euros;
            return true;
          }
          return false;
        });
        if (!existePosicion) {
          traspasosPorPosicion.push({
            Posicion: elem.Posicion,
            Fichajes: 1,
            Ventas: 0,
            "Millones de € Fichajes": elem.Euros,
            "Millones de € Ventas": 0,
          });
        }
      });
      //SEGUNDO PARSEADO PARA OBTENER VENTAS
      Data.forEach((elem) => {
        const existePosicion = traspasosPorPosicion.find((el, index) => {
          if (el.Posicion === elem.Posicion) {
            traspasosPorPosicion[index].Ventas++;
            traspasosPorPosicion[index]["Millones de € Ventas"] =
              traspasosPorPosicion[index]["Millones de € Ventas"] + elem.Euros;
            return true;
          }
          return false;
        });
        if (!existePosicion) {
          traspasosPorPosicion.push({
            Posicion: elem.Posicion,
            Fichajes: 0,
            Ventas: 1,
            "Millones de € Fichajes": 0,
            "Millones de € Ventas": elem.Euros,
          });
        }
      });
      //console.log("NUEVOS");
      //console.log(traspasosPorPosicion);
      return traspasosPorPosicion;
    },
    normalizeNumbers: function (array) {
      //NORMALIZACIÓN
      const normalized = array.map((posicion) => {
        return {
          ...posicion,
          "Millones de € Fichajes":
            Math.round(
              (posicion["Millones de € Fichajes"] + Number.EPSILON) * 100
            ) / 100,
          "Millones de € Ventas":
            Math.round(
              (posicion["Millones de € Ventas"] + Number.EPSILON) * 100
            ) / 100,
        };
      });
      //console.log("Datos Normalizados");
      //console.log(normalized);
      return normalized;
    },
    orderBy: function (array, key) {
      //ORDEN
      array.sort(function (a, b) {
        if (a[key] > b[key]) {
          return 1;
        }
        if (a[key] < b[key]) {
          return -1;
        }
        return 0;
      });
      //console.log("Datos Ordenados");
      //console.log(array);
      return array;
    },
    addAverage: function (array) {
      //AÑADIR MEDIA
      const average = array.map((posicion) => {
        const millXfichaje =
          posicion.Fichajes > 0
            ? posicion["Millones de € Fichajes"] / posicion.Fichajes
            : 0;
        const millXventa =
          posicion.Ventas > 0
            ? posicion["Millones de € Ventas"] / posicion.Ventas
            : 0;
        return {
          ...posicion,
          "Millones de € x Fichaje":
            Math.round((millXfichaje + Number.EPSILON) * 100) / 100,
          "Millones de € x Venta":
            Math.round((millXventa + Number.EPSILON) * 100) / 100,
        };
      });
      //console.log("Datos Average");
      //console.log(average);
      return average;
    },
    addDetails: function (array, myKey, dataKey, newKey) {
      //AÑADIR DETALLES
      const detailed = array.map((elem) => {
        const posicion = elem[myKey];
        //console.log(posicion);
        const filtered = Data.filter((el) => el[dataKey] === posicion);
        const final = {
          ...elem,
        };
        final[newKey] = filtered;
        return final;
      });
      //console.log("Datos Con Detalles");
      //console.log(detailed);
      return detailed;
    },
    getAverage: function (array, key, includeEmptyValues) {
      //OBETENER MEDIA
      const count = includeEmptyValues
        ? array.length
        : array.reduce((acc, el) => (el[key] > 0 ? acc + 1 : acc), 0);
      //console.log(key);
      //console.log(array);
      //console.log(count);
      const total = array.reduce(
        (acc, el) => (el[key] > 0 ? acc + el[key] : acc),
        0
      );
      //console.log(total);
      const avg = total / count;
      //console.log("Media de " + key);
      //console.log(avg);
      return avg;
    },
    adaptForFichajes: function (array) {
      //AÑADIR DETALLES
      let adapted = array.filter((el) => el.Fichajes > 0);
      //console.log("Datos adaptados para fichajes");
      //console.log(adapted);
      return adapted;
    },
    adaptForVentas: function (array) {
      //AÑADIR DETALLES
      let adapted = array.filter((el) => el.Ventas > 0);
      //console.log("Datos adaptados para ventas");
      //console.log(adapted);
      return adapted;
    },
  },
};
</script>
