<template>
  <div>
    <div class="mb-5 collapse equipoCollapse" id="equipoCollapse">
      <h2 class="display-3 mb-5 text-center">Por Equipo</h2>
      <div class="d-flex">
        <div class="w-100">
          <h3 class="display-5 mb-5 text-center">Fichajes</h3>
          <Chart
            v-if="dataFichajes"
            type="fichajes"
            :data="dataFichajes"
            :avg="avgFichajes"
            :avgMillones="avgMillonesFichajes"
            :avgMillonesX="avgMillonesFichajesX"
            :height="600"
          />
        </div>
        <div class="w-100">
          <h3 class="display-5 mb-5 text-center">Ventas</h3>
          <Chart
            v-if="dataVentas"
            type="ventas"
            :data="dataVentas"
            :avg="avgVentas"
            :avgMillones="avgMillonesVentas"
            :avgMillonesX="avgMillonesVentasX"
            :height="600"
          />
        </div>
      </div>
      <div
        class="alert alert-warning d-flex align-items-start text-start mt-5"
        role="alert"
      >
        <p class="p-3">
          Los equipos que encabezan la lista son el Real Madrid (8) y el
          Manchester City (8). A continuación aparece el Barcelona (7), PSG (5)
          y Manchester United (5). Por gasto, el que más gastó fue el Barcelona
          (656 mill.) seguido del Real Madrid (634 mill.). Sin embargo, el que
          más gastó por fichaje fue con diferencia sobre el resto el PSG (118
          mill.).
        </p>
        <p class="p-3">
          Los equipos que venden los jugadores más caros son el Borussia
          Dortmund (4), la Juventus (4) y el Atlético de Madrid (4), seguidos
          muy de cerca por 5 equipos más con 3 jugadores vendidos. El equipo que
          más ingresó es el Barcelona (356 mill.), seguido de cerca por el
          Atlético de Madrid (330 mill.) y el Mónaco (325 mill.). El que más
          ingresó por fichaje fue también el Barcelona (118 mill.), con
          solamente un millón más que el Aston Villa (117 mill.) que solamente
          tiene un fichaje en la lista, pero vendido muy caro, Jack Grealish
          (117 mill.).
        </p>
      </div>
      <div class="d-flex">
        <div class="w-100 mt-5">
          <h3 class="display-5 mt-5 mb-5 text-center">Fichajes vs Ventas</h3>
          <Chart
            v-if="dataFichajesVsVentas"
            type="fichajesVSventas"
            :data="dataFichajesVsVentas"
            :avg="avgFichajes"
            :avgMillones="avgMillonesFichajes"
            :avgMillonesX="avgMillonesFichajesX"
            :height="500"
          />
        </div>
      </div>
    </div>
    <a
      class="collapsed equipoCollapse"
      data-bs-toggle="collapse"
      href="#equipoCollapse"
      role="button"
      aria-expanded="false"
      aria-controls="equipoCollapse"
    >
    </a>
  </div>
</template>

<script>
import Data from "@/api/data.json";

import Chart from "./Chart";

export default {
  name: "ChartEquipo",
  components: {
    Chart,
  },

  data() {
    return {
      dataFichajes: [],
      dataVentas: [],
      dataFichajesVsVentas: [],
      avgFichajes: 0,
      avgVentas: 0,
      avgMillonesFichajes: 0,
      avgMillonesVentas: 0,
      avgMillonesFichajesX: 0,
      avgMillonesVentasX: 0,
    };
  },

  created() {
    //console.log("DATOS INICIALES", Data);

    const traspasosPorEquipo = this.createNewArray();
    const normalized = this.normalizeNumbers(traspasosPorEquipo);
    const average = this.addAverage(normalized, "Fichajes");
    const final1 = this.addDetails(
      average,
      "Equipo",
      "AEquipo",
      "Jugadores Fichados"
    );
    const final2 = this.addDetails(
      final1,
      "Equipo",
      "DeEquipo",
      "Jugadores Vendidos"
    );

    const avgFichajes =
      Math.round(
        (this.getAverage(final2, "Fichajes", false) + Number.EPSILON) * 100
      ) / 100;
    const avgVentas =
      Math.round(
        (this.getAverage(final2, "Ventas", false) + Number.EPSILON) * 100
      ) / 100;
    const avgMillonesFichajes =
      Math.round(
        (this.getAverage(final2, "Millones de € Fichajes", false) +
          Number.EPSILON) *
          100
      ) / 100;
    const avgMillonesVentas =
      Math.round(
        (this.getAverage(final2, "Millones de € Ventas", false) +
          Number.EPSILON) *
          100
      ) / 100;
    const avgMillonesFichajesX =
      Math.round(
        (this.getAverage(final2, "Millones de € x Fichaje", false) +
          Number.EPSILON) *
          100
      ) / 100;
    const avgMillonesVentasX =
      Math.round(
        (this.getAverage(final2, "Millones de € x Venta", false) +
          Number.EPSILON) *
          100
      ) / 100;

    this.avgFichajes = avgFichajes;
    this.avgVentas = avgVentas;
    this.avgMillonesFichajes = avgMillonesFichajes;
    this.avgMillonesVentas = avgMillonesVentas;
    this.avgMillonesFichajesX = avgMillonesFichajesX;
    this.avgMillonesVentasX = avgMillonesVentasX;

    const finalFichajes = this.adaptForFichajes(final2);
    const orderedFichajes = this.orderBy(finalFichajes, "Fichajes");
    const finalVentas = this.adaptForVentas(final2);
    const orderedVentas = this.orderBy(finalVentas, "Ventas");

    this.dataFichajes = orderedFichajes;
    this.dataVentas = orderedVentas;
    this.dataFichajesVsVentas = final2;
  },
  methods: {
    createNewArray: function () {
      //CREACIÓN NUEVO ARRAY
      const traspasosPorEquipo = [];
      //PRIMER PARSEADO PARA OBTENER FICHAJES
      Data.forEach((elem) => {
        const existeEquipo = traspasosPorEquipo.find((el, index) => {
          if (el.Equipo === elem.AEquipo) {
            traspasosPorEquipo[index].Fichajes++;
            traspasosPorEquipo[index]["Millones de € Fichajes"] =
              traspasosPorEquipo[index]["Millones de € Fichajes"] + elem.Euros;
            return true;
          }
          return false;
        });
        if (!existeEquipo) {
          traspasosPorEquipo.push({
            Equipo: elem.AEquipo,
            EquipoISO: elem.AEquipoISO,
            Fichajes: 1,
            Ventas: 0,
            "Millones de € Fichajes": elem.Euros,
            "Millones de € Ventas": 0,
          });
        }
      });
      //SEGUNDO PARSEADO PARA OBTENER VENTAS
      Data.forEach((elem) => {
        const existeEquipo = traspasosPorEquipo.find((el, index) => {
          if (el.Equipo === elem.DeEquipo) {
            traspasosPorEquipo[index].Ventas++;
            traspasosPorEquipo[index]["Millones de € Ventas"] =
              traspasosPorEquipo[index]["Millones de € Ventas"] + elem.Euros;
            return true;
          }
          return false;
        });
        if (!existeEquipo) {
          traspasosPorEquipo.push({
            Equipo: elem.DeEquipo,
            EquipoISO: elem.DeEquipoISO,
            Fichajes: 0,
            Ventas: 1,
            "Millones de € Fichajes": 0,
            "Millones de € Ventas": elem.Euros,
          });
        }
      });
      //console.log("NUEVOS");
      //console.log(traspasosPorEquipo);
      return traspasosPorEquipo;
    },
    normalizeNumbers: function (array) {
      //NORMALIZACIÓN
      const normalized = array.map((equipo) => {
        return {
          ...equipo,
          "Millones de € Fichajes":
            Math.round(
              (equipo["Millones de € Fichajes"] + Number.EPSILON) * 100
            ) / 100,
          "Millones de € Ventas":
            Math.round(
              (equipo["Millones de € Ventas"] + Number.EPSILON) * 100
            ) / 100,
        };
      });
      //console.log("Datos Normalizados");
      //console.log(normalized);
      return normalized;
    },
    orderBy: function (array, key) {
      //ORDEN
      array.sort(function (a, b) {
        if (a[key] > b[key]) {
          return 1;
        }
        if (a[key] < b[key]) {
          return -1;
        }
        return 0;
      });
      //console.log("Datos Ordenados");
      //console.log(array);
      return array;
    },
    addAverage: function (array) {
      //AÑADIR MEDIA
      const average = array.map((equipo) => {
        const millXfichaje =
          equipo.Fichajes > 0
            ? equipo["Millones de € Fichajes"] / equipo.Fichajes
            : 0;
        const millXventa =
          equipo.Ventas > 0
            ? equipo["Millones de € Ventas"] / equipo.Ventas
            : 0;
        return {
          ...equipo,
          "Millones de € x Fichaje":
            Math.round((millXfichaje + Number.EPSILON) * 100) / 100,
          "Millones de € x Venta":
            Math.round((millXventa + Number.EPSILON) * 100) / 100,
        };
      });
      //console.log("Datos Average");
      //console.log(average);
      return average;
    },
    addDetails: function (array, myKey, dataKey, newKey) {
      //AÑADIR DETALLES
      const detailed = array.map((elem) => {
        const equipo = elem[myKey];
        //console.log(equipo);
        const filtered = Data.filter((el) => el[dataKey] === equipo);
        const final = {
          ...elem,
        };
        final[newKey] = filtered;
        return final;
      });
      //console.log("Datos Con Detalles");
      //console.log(detailed);
      return detailed;
    },
    getAverage: function (array, key, includeEmptyValues) {
      //OBETENER MEDIA
      const count = includeEmptyValues
        ? array.length
        : array.reduce((acc, el) => (el[key] > 0 ? acc + 1 : acc), 0);
      //console.log(key);
      //console.log(array);
      //console.log(count);
      const total = array.reduce(
        (acc, el) => (el[key] > 0 ? acc + el[key] : acc),
        0
      );
      //console.log(total);
      const avg = total / count;
      //console.log("Media de " + key);
      //console.log(avg);
      return avg;
    },
    adaptForFichajes: function (array) {
      //AÑADIR DETALLES
      let adapted = array.filter((el) => el.Fichajes > 0);
      //console.log("Datos adaptados para fichajes");
      //console.log(adapted);
      return adapted;
    },
    adaptForVentas: function (array) {
      //AÑADIR DETALLES
      let adapted = array.filter((el) => el.Ventas > 0);
      //console.log("Datos adaptados para ventas");
      //console.log(adapted);
      return adapted;
    },
  },
};
</script>

<style scoped>
.collapse {
  position: relative;
  /* For ...'s content absolute positioning */
  display: block !important;
  overflow: hidden;
}

a {
  color: rgb(87, 81, 81) !important;
  font-weight: 600;
  text-decoration: none;
}

.collapse:before {
  content: " ...";
  position: absolute;
  right: 0;
  bottom: 0;
}

.collapse.show {
  height: auto;
  /* You need to reset the height when not collapsed */
}

.collapse.show:before {
  display: none;
  /* Of course you don't want to display ... */
}

a.collapsed:after {
  content: "+ Ver más";
}

a:not(.collapsed):after {
  content: "- Ver menos";
}

a.collapsed.equiposMasFichajesCollapse:after,
a.collapsed.equiposMasGastadoCollapse:after,
a.collapsed.equiposMasVendidosCollapse:after,
a.collapsed.equiposMasIngresosCollapse:after,
a.collapsed.origenCollapse:after {
  content: "+ Ampliar tabla";
}

a:not(.collapsed).equiposMasFichajesCollapse:after,
a:not(.collapsed).equiposMasGastadoCollapse:after,
a:not(.collapsed).equiposMasVendidosCollapse:after,
a:not(.collapsed).equiposMasIngresosCollapse:after,
a:not(.collapsed).origenCollapse:after {
  content: "- Cerrar tabla";
}

.equipoCollapse {
  height: 73em;
  /* [NUM_OF_LINES] x [LINE_HEIGHT] */
}
</style>
