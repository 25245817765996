<template>
  <div id="chartOrigenWrapper" class="mb-3">
    <h2 class="display-3 text-center">Por País de Origen</h2>
    <div class="d-flex">
      <div class="w-100 my-5">
        <Chart
          v-if="dataFichajes"
          type="fichajes"
          :data="dataFichajes"
          :avg="avgFichajes"
          :avgMillones="avgMillonesFichajes"
          :avgMillonesX="avgMillonesFichajesX"
          :height="600"
        />
      </div>
    </div>
    <div
      class="alert alert-warning d-flex align-items-start text-start mt-3 mb-5"
      role="alert"
    >
      <p class="p-3">
        Los jugadores más caros nacen en Brasil (104 mill.). Sin embargo, el
        país que más dinero a generado en jugadores caros es Francia (861
        mill.). Destaca la aparición de países con poca tradición futbolera como
        Guinea, Gabón, Argelia o Costa de Marfil, que estarán muy orgullosos de
        que sus jugadores aparezcan en esta lista.
      </p>
    </div>
  </div>
</template>

<script>
import Data from "@/api/data.json";

import Chart from "./Chart";

export default {
  name: "ChartOrigen",
  components: {
    Chart,
  },

  data() {
    return {
      dataFichajes: [],
      dataVentas: [],
      dataFichajesVsVentas: [],
      avgFichajes: 0,
      avgVentas: 0,
      avgMillonesFichajes: 0,
      avgMillonesVentas: 0,
      avgMillonesFichajesX: 0,
      avgMillonesVentasX: 0,
    };
  },

  created() {
    //console.log("DATOS INICIALES", Data);

    const traspasosPorOrigen = this.createNewArray();
    const normalized = this.normalizeNumbers(traspasosPorOrigen);
    const average = this.addAverage(normalized, "Fichajes");
    const final1 = this.addDetails(
      average,
      "Origen",
      "Origen",
      "Jugadores Fichados"
    );
    const final2 = this.addDetails(
      final1,
      "Origen",
      "Origen",
      "Jugadores Vendidos"
    );

    const avgFichajes =
      Math.round(
        (this.getAverage(final2, "Fichajes", false) + Number.EPSILON) * 100
      ) / 100;
    const avgVentas =
      Math.round(
        (this.getAverage(final2, "Ventas", false) + Number.EPSILON) * 100
      ) / 100;
    const avgMillonesFichajes =
      Math.round(
        (this.getAverage(final2, "Millones de € Fichajes", false) +
          Number.EPSILON) *
          100
      ) / 100;
    const avgMillonesVentas =
      Math.round(
        (this.getAverage(final2, "Millones de € Ventas", false) +
          Number.EPSILON) *
          100
      ) / 100;
    const avgMillonesFichajesX =
      Math.round(
        (this.getAverage(final2, "Millones de € x Fichaje", false) +
          Number.EPSILON) *
          100
      ) / 100;
    const avgMillonesVentasX =
      Math.round(
        (this.getAverage(final2, "Millones de € x Venta", false) +
          Number.EPSILON) *
          100
      ) / 100;

    this.avgFichajes = avgFichajes;
    this.avgVentas = avgVentas;
    this.avgMillonesFichajes = avgMillonesFichajes;
    this.avgMillonesVentas = avgMillonesVentas;
    this.avgMillonesFichajesX = avgMillonesFichajesX;
    this.avgMillonesVentasX = avgMillonesVentasX;

    const finalFichajes = this.adaptForFichajes(final2);
    const orderedFichajes = this.orderBy(finalFichajes, "Fichajes");
    const finalVentas = this.adaptForVentas(final2);
    const orderedVentas = this.orderBy(finalVentas, "Ventas");

    this.dataFichajes = orderedFichajes;
    this.dataVentas = orderedVentas;
    this.dataFichajesVsVentas = final2;
  },
  methods: {
    createNewArray: function () {
      //CREACIÓN NUEVO ARRAY
      const traspasosPorOrigen = [];
      //PRIMER PARSEADO PARA OBTENER FICHAJES
      Data.forEach((elem) => {
        const existeOrigen = traspasosPorOrigen.find((el, index) => {
          if (el.Origen === elem.Origen) {
            traspasosPorOrigen[index].Fichajes++;
            traspasosPorOrigen[index]["Millones de € Fichajes"] =
              traspasosPorOrigen[index]["Millones de € Fichajes"] + elem.Euros;
            return true;
          }
          return false;
        });
        if (!existeOrigen) {
          traspasosPorOrigen.push({
            Origen: elem.Origen,
            OrigenISO: elem.OrigenISO,
            Fichajes: 1,
            Ventas: 0,
            "Millones de € Fichajes": elem.Euros,
            "Millones de € Ventas": 0,
          });
        }
      });
      //SEGUNDO PARSEADO PARA OBTENER VENTAS
      Data.forEach((elem) => {
        const existeOrigen = traspasosPorOrigen.find((el, index) => {
          if (el.Origen === elem.Origen) {
            traspasosPorOrigen[index].Ventas++;
            traspasosPorOrigen[index]["Millones de € Ventas"] =
              traspasosPorOrigen[index]["Millones de € Ventas"] + elem.Euros;
            return true;
          }
          return false;
        });
        if (!existeOrigen) {
          traspasosPorOrigen.push({
            Origen: elem.Origen,
            OrigenISO: elem.OrigenISO,
            Fichajes: 0,
            Ventas: 1,
            "Millones de € Fichajes": 0,
            "Millones de € Ventas": elem.Euros,
          });
        }
      });
      //console.log("NUEVOS");
      //console.log(traspasosPorOrigen);
      return traspasosPorOrigen;
    },
    normalizeNumbers: function (array) {
      //NORMALIZACIÓN
      const normalized = array.map((origen) => {
        return {
          ...origen,
          "Millones de € Fichajes":
            Math.round(
              (origen["Millones de € Fichajes"] + Number.EPSILON) * 100
            ) / 100,
          "Millones de € Ventas":
            Math.round(
              (origen["Millones de € Ventas"] + Number.EPSILON) * 100
            ) / 100,
        };
      });
      //console.log("Datos Normalizados");
      //console.log(normalized);
      return normalized;
    },
    orderBy: function (array, key) {
      //ORDEN
      array.sort(function (a, b) {
        if (a[key] > b[key]) {
          return 1;
        }
        if (a[key] < b[key]) {
          return -1;
        }
        return 0;
      });
      //console.log("Datos Ordenados");
      //console.log(array);
      return array;
    },
    addAverage: function (array) {
      //AÑADIR MEDIA
      const average = array.map((origen) => {
        const millXfichaje =
          origen.Fichajes > 0
            ? origen["Millones de € Fichajes"] / origen.Fichajes
            : 0;
        const millXventa =
          origen.Ventas > 0
            ? origen["Millones de € Ventas"] / origen.Ventas
            : 0;
        return {
          ...origen,
          "Millones de € x Fichaje":
            Math.round((millXfichaje + Number.EPSILON) * 100) / 100,
          "Millones de € x Venta":
            Math.round((millXventa + Number.EPSILON) * 100) / 100,
        };
      });
      //console.log("Datos Average");
      //console.log(average);
      return average;
    },
    addDetails: function (array, myKey, dataKey, newKey) {
      //AÑADIR DETALLES
      const detailed = array.map((elem) => {
        const origen = elem[myKey];
        //console.log(origen);
        const filtered = Data.filter((el) => el[dataKey] === origen);
        const final = {
          ...elem,
        };
        final[newKey] = filtered;
        return final;
      });
      //console.log("Datos Con Detalles");
      //console.log(detailed);
      return detailed;
    },
    getAverage: function (array, key, includeEmptyValues) {
      //OBETENER MEDIA
      const count = includeEmptyValues
        ? array.length
        : array.reduce((acc, el) => (el[key] > 0 ? acc + 1 : acc), 0);
      //console.log(key);
      //console.log(array);
      //console.log(count);
      const total = array.reduce(
        (acc, el) => (el[key] > 0 ? acc + el[key] : acc),
        0
      );
      //console.log(total);
      const avg = total / count;
      //console.log("Media de " + key);
      //console.log(avg);
      return avg;
    },
    adaptForFichajes: function (array) {
      //AÑADIR DETALLES
      let adapted = array.filter((el) => el.Fichajes > 0);
      //console.log("Datos adaptados para fichajes");
      //console.log(adapted);
      return adapted;
    },
    adaptForVentas: function (array) {
      //AÑADIR DETALLES
      let adapted = array.filter((el) => el.Ventas > 0);
      //console.log("Datos adaptados para ventas");
      //console.log(adapted);
      return adapted;
    },
  },
};
</script>
