<template>
  <div id="chartOrigen">
    <!--MODAL-->
    <Modal
      :type="typeModal"
      :id="idModal"
      :origen="origenModal"
      :euros="eurosModal"
      :media="mediaModal"
      :fichajes="fichajesModal"
    />

    <!--SELECTOR-->
    <v-select
      class="style-chooserOrigen"
      :options="possibleOptions"
      :value="selectedOption"
      @input="setSelected"
    ></v-select>

    <!--GRÁFICO-->
    <ResponsiveBar
      class="bar"
      v-bind="chartCommonProps"
      :keys="selectedKey"
      :style="{ height: height + 'px' }"
    />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import Colors from "@/api/colors.json";

import React from "react";
import * as bootstrap from "bootstrap";

import { ResponsiveBar } from "@nivo/bar";

import Modal from "./Modal";

import SVG from "@/components/common/flagSVG";

export default {
  name: "Chart",
  components: {
    ResponsiveBar,
    Modal,
  },
  props: {
    type: String,
    data: Array,
    avg: Number,
    avgMillones: Number,
    avgMillonesX: Number,
    height: Number,
  },
  created() {
    //console.log(this.type);
    this.idModal = `modalOrigen${this.type}`;
    if (this.type === "fichajes") {
      this.selectedKey = ["Fichajes"];
      this.selectedOption = "¿Dónde nacen los jugadores más caros?";
      this.possibleOptions = [
        "¿Dónde nacen los jugadores más caros?",
        "¿Dónde nacen los jugadores en los que se invierte más dinero en total?",
        "¿Dónde nacen los jugadores en los que se invierte más dinero por fichaje?",
      ];
      this.chartCommonProps.markers[0].value = this.avg;
      this.chartCommonProps.markers[0].legend = `${this.avg} fichajes por país de origen`;
      this.chartCommonProps.data = this.orderBy(this.data, "Fichajes", "asc");
      this.chartCommonProps.axisBottom.legend =
        "Fichajes por país de origen en el top 50";
    } else if (this.type === "ventas") {
      this.selectedKey = ["Ventas"];
      this.selectedOption = "¿Quiénes vendieron más?";
      this.possibleOptions = [
        "¿Quiénes vendieron más?",
        "¿Quiénes ingresaron más en total?",
        "¿Quiénes ingresaron más por fichaje?",
      ];
      this.chartCommonProps.markers[0].value = this.avg;
      this.chartCommonProps.markers[0].legend = `${this.avg} ventas por país de origen`;
      this.chartCommonProps.data = this.orderBy(this.data, "Ventas", "asc");
      this.chartCommonProps.axisBottom.legend =
        "Ventas por país de origen en el top 50";
    } else {
      this.selectedKey = ["Fichajes", "Ventas"];
      this.selectedOption = "Contador";
      this.possibleOptions = [
        "Contador",
        "Millones de €",
        "Millones de € x traspaso",
      ];
      this.chartCommonProps.markers = [];
      this.chartCommonProps.colorBy = "indexValue";
      this.chartCommonProps.colors = ({ id, data }) => {
        //console.log(id);
        //console.log(data);
        let color = String(
          Colors.find((el) => el.code === data.Origen)?.color || "#333333"
        );
        if (
          id === "Ventas" ||
          id === "Millones de € Ventas" ||
          id === "Millones de € x Venta"
        ) {
          color = `${color}7d`;
        }
        return color;
      };
      this.chartCommonProps.data = this.orderBy(this.data, "Fichajes", "desc");
      this.chartCommonProps.layout = "vertical";
      this.chartCommonProps.axisBottom.tickRotation = -35;
      this.chartCommonProps.margin.bottom = 130;
      this.chartCommonProps.margin.left = 50;
      this.chartCommonProps.axisBottom.legendOffset = 120;
      this.chartCommonProps.axisBottom.legend =
        "Fichajes (col. izq.) vs Ventas (col. der.) por país de origen en el top 50";
    }
  },
  data() {
    return {
      idModal: "",
      fichajesModal: [],
      typeModal: "",
      origenModal: "",
      eurosModal: 0,
      mediaModal: 0,
      selectedKey: [],
      selectedOption: "",
      possibleOptions: [],
      chartCommonProps: {
        //BASE
        data: [],
        indexBy: "Origen",
        groupMode: "grouped",
        layout: "horizontal",
        valueScale: { type: "linear" },
        indexScale: { type: "band", round: true },
        reverse: false,
        minValue: "auto",
        maxValue: "auto",
        padding: 0.2,
        innerPadding: 2,
        //width: 100,
        //height: 100,
        margin: { top: 25, right: 60, bottom: 60, left: 150 },

        //STYLE
        theme: {
          background: "#ffffff",
          textColor: "#333333",
          fontSize: 14,
        },
        colors: ({ id, data }) => {
          //console.log(data.OrigenISO);
          return String(
            Colors.find((el) => el.code === data.OrigenISO)?.color || "#333333"
          );
        },
        colorBy: "id",
        borderRadius: 5,
        borderWidth: 0,
        borderColor: "#171e32e6",

        //CUSTOMIZATION
        layers: ["grid", "axes", "bars", "markers", "legends", "annotations"],

        //LABELS
        enableLabel: true,
        label: this.label,
        labelSkipWidth: 14,
        labelSkipHeight: 14,
        labelTextColor: "#fff",

        //GRID AND AXES
        enableGridX: false,
        //gridXValues: 1,
        enableGridY: false,
        //gridYValues: 2,
        axisTop: null,
        axisRight: null,
        axisBottom: {
          legend: "",
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: 50,
        },
        axisLeft: {
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: -40,
          //renderTick: this.customTick,
        },

        //INTERACTIVITY
        isInteractive: true,
        tooltip: this.tooltip,
        onClick: this.onClick,

        //LEGENDS
        legends: [],

        //MOTION
        animate: true,
        motionConfig: "slow",

        //ACCESSIBILITY
        isFocusable: false,

        //MARKERS
        markers: [
          {
            axis: "x",
            value: 4.5,
            lineStyle: { stroke: "rgba(0, 0, 0, .35)", strokeWidth: 1 },
            legend: "Promedio 4.5",
            legendOrientation: "horizontal",
            legendPosition: "bottom-right",
          },
        ],

        //ANNOTATIONS
        annotations: [
          {
            type: "circle",
            match: { key: "Millones de € x Fichaje.Paris Saint-Germain" },
            noteX: 40,
            noteY: 0,
            offset: 0,
            noteTextOffset: -3,
            noteWidth: 0,
            note: "El PSG compra muy caro!",
            size: 60,
          },
        ],
      },
    };
  },
  methods: {
    label: function (e) {
      //console.log(e);
      return `${e.value}`;
    },
    customTick: function (tick) {
      //console.log(tick);
      let size = "";
      if (this.type === "fichajes" || this.type === "ventas") {
        size = "half";
      } else {
        size = "full";
      }
      return SVG(tick.value, tick, size, this.chartCommonProps.layout);
    },
    tooltip: function (e) {
      //console.log(e);
      if (this.type === "fichajes" || this.type === "ventas") {
        return (
          <div
            className="card"
            style={{ background: "#171e32", color: "white" }}
          >
            <div className="card-header">
              <strong>{e.data.Origen} </strong>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <strong>
                  {this.type === "fichajes" ? e.data.Fichajes : e.data.Ventas}
                </strong>{" "}
                {this.type === "fichajes" ? "Fichajes" : "Ventas"}
              </li>
              <li className="list-group-item">
                <strong>
                  {this.type === "fichajes"
                    ? e.data["Millones de € x Fichaje"]
                    : e.data["Millones de € x Venta"]}
                </strong>{" "}
                Mill. de € x {this.type === "fichaje" ? "fichajes" : "venta"}
              </li>
              <li className="list-group-item">
                <strong>
                  {this.type === "fichajes"
                    ? e.data["Millones de € Fichajes"]
                    : e.data["Millones de € Ventas"]}
                </strong>{" "}
                Mill. de € en total
              </li>
            </ul>
          </div>
        );
      } else {
        return (
          <div
            className="card"
            style={{ background: "#171e32", color: "white" }}
          >
            <div className="card-header">
              <strong>{e.data.Origen} </strong>
            </div>
            <div className="d-flex">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <strong>{e.data.Fichajes}</strong> Fichajes
                </li>
                <li className="list-group-item">
                  <strong>{e.data["Millones de € x Fichaje"]}</strong> Mill. de
                  € x fichajes
                </li>
                <li className="list-group-item">
                  <strong>{e.data["Millones de € Fichajes"]}</strong> Mill. de €
                  en total
                </li>
              </ul>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <strong>{e.data.Ventas}</strong> Ventas
                </li>
                <li className="list-group-item">
                  <strong>{e.data["Millones de € x Venta"]}</strong> Mill. de €
                  x venta
                </li>
                <li className="list-group-item">
                  <strong>{e.data["Millones de € Ventas"]}</strong> Mill. de €
                  en total
                </li>
              </ul>
            </div>
          </div>
        );
      }
    },
    onClick: function (e) {
      //console.log(e);
      //console.log(e.id);
      if (e.id === "Fichajes") {
        this.fichajesModal = e.data["Jugadores Fichados"];
        this.origenModal = e.data.Origen;
        this.eurosModal = e.data["Millones de € Fichajes"];
        this.mediaModal = e.data["Millones de € x Fichaje"];
      } else {
        this.fichajesModal = e.data["Jugadores Vendidos"];
        this.origenModal = e.data.Origen;
        this.eurosModal = e.data["Millones de € Ventas"];
        this.mediaModal = e.data["Millones de € x Venta"];
      }
      this.typeModal = e.id;
      const modal = new bootstrap.Modal(document.getElementById(this.idModal));
      modal.show();
      return;
    },
    setSelected: function (value) {
      if (value !== null) {
        //console.log("value", value);
        let selected = "";
        let asc = "asc";
        switch (value) {
          case "¿Dónde nacen los jugadores más caros?":
            selected = ["Fichajes"];
            this.chartCommonProps.axisBottom.legend =
              "Fichajes por país de origen en el top 50";
            break;
          case "¿Dónde nacen los jugadores en los que se invierte más dinero en total?":
            selected = ["Millones de € Fichajes"];
            this.chartCommonProps.axisBottom.legend =
              "Millones de € totales gastados en fichajes por país de origen en el top 50";
            break;
          case "¿Dónde nacen los jugadores en los que se invierte más dinero por fichaje?":
            selected = ["Millones de € x Fichaje"];
            this.chartCommonProps.axisBottom.legend =
              "Millones de € gastados de media en fichajes por país de origen en el top 50";
            break;
          case "¿Quiénes vendieron más?":
            selected = ["Ventas"];
            this.chartCommonProps.axisBottom.legend =
              "Ventas por país de origen en el top 50";
            break;
          case "¿Quiénes ingresaron más en total?":
            selected = ["Millones de € Ventas"];
            this.chartCommonProps.axisBottom.legend =
              "Millones de € totales ingresados en ventas por país de origen en el top 50";
            break;
          case "¿Quiénes ingresaron más por fichaje?":
            selected = ["Millones de € x Venta"];
            this.chartCommonProps.axisBottom.legend =
              "Millones de € ingresados de media en ventas por país de origen en el top 50";
            break;
          case "Contador":
            selected = ["Fichajes", "Ventas"];
            this.chartCommonProps.axisBottom.legend =
              "Fichajes (col. izq.) vs Ventas (col. der.) por país de origen en el top 50";
            asc = "desc";
            break;
          case "Millones de €":
            selected = ["Millones de € Fichajes", "Millones de € Ventas"];
            this.chartCommonProps.axisBottom.legend =
              "Millones de € totales gastados (col. izq.) vs ingresados (col. der.) por país de origen en el top 50";
            asc = "desc";
            break;
          case "Millones de € x traspaso":
            selected = ["Millones de € x Fichaje", "Millones de € x Venta"];
            this.chartCommonProps.axisBottom.legend =
              "Millones de € gastados de media en fichajes (col. izq.) vs ingresados de media en ventas (col. der.) por país de origen en el top 50";
            asc = "desc";
            break;

          default:
            break;
        }
        this.selectedKey = selected;
        this.selectedOption = value;
        if (selected.length === 1) {
          if (selected[0] === "Fichajes" || selected[0] === "Ventas") {
            this.chartCommonProps.markers[0].value = this.avg;
            this.chartCommonProps.markers[0].legend = `${this.avg} fichajes por país de origen`;
          } else if (
            selected[0] === "Millones de € Fichajes" ||
            selected[0] === "Millones de € Ventas"
          ) {
            this.chartCommonProps.markers[0].value = this.avgMillones;
            this.chartCommonProps.markers[0].legend = `${this.avgMillones} mill €`;
          } else if (
            selected[0] === "Millones de € x Fichaje" ||
            selected[0] === "Millones de € x Venta"
          ) {
            this.chartCommonProps.markers[0].value = this.avgMillonesX;
            this.chartCommonProps.markers[0].legend = `${this.avgMillonesX} mill €`;
          }
        }
        this.chartCommonProps.data = this.orderBy(
          this.chartCommonProps.data,
          selected[0],
          asc
        );
      }
      return;
    },
    orderBy: function (array, key, asc) {
      //console.log(asc);
      //ORDEN
      if (asc === "asc") {
        array.sort(function (a, b) {
          if (a[key] > b[key]) {
            return 1;
          }
          if (a[key] < b[key]) {
            return -1;
          }
          return 0;
        });
      } else {
        array.sort(function (a, b) {
          if (a[key] < b[key]) {
            return 1;
          }
          if (a[key] > b[key]) {
            return -1;
          }
          return 0;
        });
      }
      //console.log("Datos Ordenados");
      //console.log(array);
      return array;
    },
  },
};
</script>

<style scoped>
#chartOrigen {
  text-align: center;
}

.bar {
  height: 400px;
  width: 100%;
  cursor: pointer;
}
</style>

<style>
.style-chooserOrigen .vs__search::placeholder,
.style-chooserOrigen .vs__dropdown-toggle,
.style-chooserOrigen .vs__dropdown-menu {
  background: #f1f1f1;
  border: none;
  color: var(--primary);
  text-transform: uppercase;
  width: 97%;
  padding: 0.5rem;
}

.style-chooserOrigen .vs__selected,
.style-chooserOrigen .vs__dropdown-option {
  font-size: 1.2rem;
  color: var(--primary) !important;
  fill: white;
}

.style-chooserOrigen .vs__clear {
  fill: #f1f1f1;
}

.style-chooserOrigen .vs__open-indicator {
  fill: var(--primary) !important;
}

.style-chooserOrigen .vs__open-indicator,
.style-chooserOrigen .vs__selected,
.style-chooserOrigen .vs__dropdown-option {
  color: #fff;
  fill: white;
}

.style-chooserOrigen .vs__dropdown-option--highlight {
  background: #fff;
  color: var(--primary);
}
</style>
