<template>
  <div id="chartPaisWrapper" class="mb-5">
    <h2 class="display-3 mb-5 text-center">Por País</h2>
    <div class="d-flex">
      <div class="w-100">
        <h3 class="display-5 mb-5 text-center">Fichajes</h3>
        <Chart
          v-if="dataFichajes"
          type="fichajes"
          :data="dataFichajes"
          :avg="avgFichajes"
          :avgMillones="avgMillonesFichajes"
          :avgMillonesX="avgMillonesFichajesX"
          :height="400"
        />
      </div>
      <div class="w-100">
        <h3 class="display-5 mb-5 text-center">Ventas</h3>
        <Chart
          v-if="dataVentas"
          type="ventas"
          :data="dataVentas"
          :avg="avgVentas"
          :avgMillones="avgMillonesVentas"
          :avgMillonesX="avgMillonesVentasX"
          :height="400"
        />
      </div>
    </div>
    <div
      class="alert alert-warning d-flex align-items-start text-start mt-5"
      role="alert"
    >
      <p class="p-3">
        Los países que más fichajes realizaron con diferencia sobre el resto son
        Inglaterra (23) y España (17). Por tanto, también son los que más dinero
        gastaron en este tipo de fichajes. Sin embargo, España (87,5 mill.)
        gasto más dinero por fichaje que Inglaterra (75 mill.), clasificación en
        la que destaca como outlier Francia (108 mill.).
      </p>
      <p class="p-3">
        El país que más vendió fue de nuevo Inglaterra (14), seguido de cerca
        por España (12) e Italia (10). El que más ingresó en total también fue
        Inglaterra (1230 mill.), sin embargo, fue Portugal el que más ingresó
        por fichaje (97 mill.) seguido de España (89 mill.) y Francia (89
        mill.).
      </p>
    </div>
    <div class="d-flex">
      <div class="w-100 mt-5">
        <h3 class="display-5 mt-5 mb-5 text-center">Fichajes vs Ventas</h3>
        <Chart
          v-if="dataFichajesVsVentas"
          type="fichajesVSventas"
          :data="dataFichajesVsVentas"
          :avg="avgFichajes"
          :avgMillones="avgMillonesFichajes"
          :avgMillonesX="avgMillonesFichajesX"
          :height="450"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Data from "@/api/data.json";

import Chart from "./Chart";

export default {
  name: "ChartPais",
  components: {
    Chart,
  },

  data() {
    return {
      dataFichajes: [],
      dataVentas: [],
      dataFichajesVsVentas: [],
      avgFichajes: 0,
      avgVentas: 0,
      avgMillonesFichajes: 0,
      avgMillonesVentas: 0,
      avgMillonesFichajesX: 0,
      avgMillonesVentasX: 0,
    };
  },

  created() {
    //console.log("DATOS INICIALES", Data);

    const traspasosPorPais = this.createNewArray();
    const normalized = this.normalizeNumbers(traspasosPorPais);
    const average = this.addAverage(normalized, "Fichajes");
    const final1 = this.addDetails(
      average,
      "Pais",
      "APais",
      "Jugadores Fichados"
    );
    const final2 = this.addDetails(
      final1,
      "Pais",
      "DePais",
      "Jugadores Vendidos"
    );

    const avgFichajes =
      Math.round(
        (this.getAverage(final2, "Fichajes", false) + Number.EPSILON) * 100
      ) / 100;
    const avgVentas =
      Math.round(
        (this.getAverage(final2, "Ventas", false) + Number.EPSILON) * 100
      ) / 100;
    const avgMillonesFichajes =
      Math.round(
        (this.getAverage(final2, "Millones de € Fichajes", false) +
          Number.EPSILON) *
          100
      ) / 100;
    const avgMillonesVentas =
      Math.round(
        (this.getAverage(final2, "Millones de € Ventas", false) +
          Number.EPSILON) *
          100
      ) / 100;
    const avgMillonesFichajesX =
      Math.round(
        (this.getAverage(final2, "Millones de € x Fichaje", false) +
          Number.EPSILON) *
          100
      ) / 100;
    const avgMillonesVentasX =
      Math.round(
        (this.getAverage(final2, "Millones de € x Venta", false) +
          Number.EPSILON) *
          100
      ) / 100;

    this.avgFichajes = avgFichajes;
    this.avgVentas = avgVentas;
    this.avgMillonesFichajes = avgMillonesFichajes;
    this.avgMillonesVentas = avgMillonesVentas;
    this.avgMillonesFichajesX = avgMillonesFichajesX;
    this.avgMillonesVentasX = avgMillonesVentasX;

    const finalFichajes = this.adaptForFichajes(final2);
    const orderedFichajes = this.orderBy(finalFichajes, "Fichajes");
    const finalVentas = this.adaptForVentas(final2);
    const orderedVentas = this.orderBy(finalVentas, "Ventas");

    this.dataFichajes = orderedFichajes;
    this.dataVentas = orderedVentas;
    this.dataFichajesVsVentas = final2;
  },
  methods: {
    createNewArray: function () {
      //CREACIÓN NUEVO ARRAY
      const traspasosPorPais = [];
      //PRIMER PARSEADO PARA OBTENER FICHAJES
      Data.forEach((elem) => {
        const existeCountry = traspasosPorPais.find((el, index) => {
          if (el.Pais === elem.APais) {
            traspasosPorPais[index].Fichajes++;
            traspasosPorPais[index]["Millones de € Fichajes"] =
              traspasosPorPais[index]["Millones de € Fichajes"] + elem.Euros;
            return true;
          }
          return false;
        });
        if (!existeCountry) {
          traspasosPorPais.push({
            Pais: elem.APais,
            PaisISO: elem.APaisISO,
            Fichajes: 1,
            Ventas: 0,
            "Millones de € Fichajes": elem.Euros,
            "Millones de € Ventas": 0,
          });
        }
      });
      //SEGUNDO PARSEADO PARA OBTENER VENTAS
      Data.forEach((elem) => {
        const existeCountry = traspasosPorPais.find((el, index) => {
          if (el.Pais === elem.DePais) {
            traspasosPorPais[index].Ventas++;
            traspasosPorPais[index]["Millones de € Ventas"] =
              traspasosPorPais[index]["Millones de € Ventas"] + elem.Euros;
            return true;
          }
          return false;
        });
        if (!existeCountry) {
          traspasosPorPais.push({
            Pais: elem.DePais,
            PaisISO: elem.DePaisISO,
            Fichajes: 0,
            Ventas: 1,
            "Millones de € Fichajes": 0,
            "Millones de € Ventas": elem.Euros,
          });
        }
      });
      //console.log("NUEVOS");
      //console.log(traspasosPorPais);
      return traspasosPorPais;
    },
    normalizeNumbers: function (array) {
      //NORMALIZACIÓN
      const normalized = array.map((pais) => {
        return {
          ...pais,
          "Millones de € Fichajes":
            Math.round(
              (pais["Millones de € Fichajes"] + Number.EPSILON) * 100
            ) / 100,
          "Millones de € Ventas":
            Math.round((pais["Millones de € Ventas"] + Number.EPSILON) * 100) /
            100,
        };
      });
      //console.log("Datos Normalizados");
      //console.log(normalized);
      return normalized;
    },
    orderBy: function (array, key) {
      //ORDEN
      array.sort(function (a, b) {
        if (a[key] > b[key]) {
          return 1;
        }
        if (a[key] < b[key]) {
          return -1;
        }
        return 0;
      });
      //console.log("Datos Ordenados");
      //console.log(array);
      return array;
    },
    addAverage: function (array) {
      //AÑADIR MEDIA
      const average = array.map((pais) => {
        const millXfichaje =
          pais.Fichajes > 0
            ? pais["Millones de € Fichajes"] / pais.Fichajes
            : 0;
        const millXventa =
          pais.Ventas > 0 ? pais["Millones de € Ventas"] / pais.Ventas : 0;
        return {
          ...pais,
          "Millones de € x Fichaje":
            Math.round((millXfichaje + Number.EPSILON) * 100) / 100,
          "Millones de € x Venta":
            Math.round((millXventa + Number.EPSILON) * 100) / 100,
        };
      });
      //console.log("Datos Average");
      //console.log(average);
      return average;
    },
    addDetails: function (array, myKey, dataKey, newKey) {
      //AÑADIR DETALLES
      const detailed = array.map((elem) => {
        const pais = elem[myKey];
        //console.log(pais);
        const filtered = Data.filter((el) => el[dataKey] === pais);
        const final = {
          ...elem,
        };
        final[newKey] = filtered;
        return final;
      });
      //console.log("Datos Con Detalles");
      //console.log(detailed);
      return detailed;
    },
    getAverage: function (array, key, includeEmptyValues) {
      //OBETENER MEDIA
      const count = includeEmptyValues
        ? array.length
        : array.reduce((acc, el) => (el[key] > 0 ? acc + 1 : acc), 0);
      //console.log(key);
      //console.log(array);
      //console.log(count);
      const total = array.reduce(
        (acc, el) => (el[key] > 0 ? acc + el[key] : acc),
        0
      );
      //console.log(total);
      const avg = total / count;
      //console.log("Media de " + key);
      //console.log(avg);
      return avg;
    },
    adaptForFichajes: function (array) {
      //AÑADIR DETALLES
      let adapted = array.filter((el) => el.Fichajes > 0);
      //console.log("Datos adaptados para fichajes");
      //console.log(adapted);
      return adapted;
    },
    adaptForVentas: function (array) {
      //AÑADIR DETALLES
      let adapted = array.filter((el) => el.Ventas > 0);
      //console.log("Datos adaptados para ventas");
      //console.log(adapted);
      return adapted;
    },
  },
};
</script>
