<template>
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    aria-labelledby="modalFichajesLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-xl modal-fullscreen-lg-down
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close order-1 ms-2 align-self-start mt-1"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div class="d-flex align-items-end flex-column order-2">
            <h5 class="modal-title text-center" id="modalFichajesLabel">
              <span :style="{ color: 'saddlebrown' }">{{
                fichajes.length
              }}</span>
              <span v-if="fichajes.length === 1">
                <span v-if="type === 'Fichajes'"> jugador fichado</span>
                <span v-else> jugador vendido</span>
              </span>
              <span v-else>
                <span v-if="type === 'Fichajes'"> jugadores fichados</span>
                <span v-else> jugadores vendidos</span>
              </span>
              con posición
              <span :style="{ color: 'saddlebrown' }">{{ posicion }}</span> por
              un total de
              <span :style="{ color: 'saddlebrown' }">{{ euros }}</span>
              millones de €,
            </h5>
            <h5 class="modal-title text-center" id="modalFichajesLabel">
              con un
              <span v-if="type === 'Fichajes'"> gasto medio por fichaje </span>
              <span v-else> ingreso medio por venta </span>
              de
              <span :style="{ color: 'saddlebrown' }">{{ media }}</span>
              millones de €.
            </h5>
          </div>
        </div>
        <div class="modal-body">
          <table class="w-100 table table-striped table-hover align-middle">
            <thead class="table-dark">
              <tr>
                <th>Jugador</th>
                <th>Posición</th>
                <th colspan="2">Origen</th>
                <th colspan="2">Destino</th>
                <th>Precio</th>
                <th>Ranking</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(fichaje, index) in fichajes" :key="index">
                <td
                  class="text-center d-flex flex-column align-items-center py-3"
                >
                  <div class="row fw-bold">{{ fichaje.Jugador }}</div>
                  <div class="row">{{ fichaje.Nacido }}</div>
                  <country-flag :country="fichaje.OrigenISO" size="normal" />
                </td>
                <td class="text-center">{{ fichaje.Posicion }}</td>
                <td class="text-end">
                  <country-flag
                    class="ms-4"
                    :country="fichaje.DePaisISO"
                    size="normal"
                  />
                </td>
                <td class="text-start">{{ fichaje.DeEquipo }}</td>
                <td class="text-end">
                  <country-flag
                    class="ms-4"
                    :country="fichaje.APaisISO"
                    size="normal"
                  />
                </td>
                <td class="text-start">{{ fichaje.AEquipo }}</td>
                <td class="text-center">
                  <strong>{{ fichaje.Euros }}</strong> mill de €
                </td>
                <td class="text-center">{{ fichaje.Ranking }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";

export default {
  name: "Modal",
  props: {
    type: String,
    id: String,
    posicion: String,
    euros: Number,
    media: Number,
    fichajes: Array,
  },
  components: {
    CountryFlag,
  },
};
</script>

<style>
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem auto -0.5rem -0.5rem;
}
</style>