<template>
  <div>
    <h1 class="display-1 text-center my-5">
      Los 50 fichajes más caros del fútbol
    </h1>
    <img
      src="@/assets/img/neymar-mbappe.jpg"
      class="img-fluid mb-5"
      alt="Neymar Mbappe"
    />
    <div class="alert mx-auto mb-5" role="alert">
      <div class="wrapper">
        <h4 class="alert-heading">Hay que tener en cuenta</h4>
        <hr />
        <ul class="mb-5">
          <li>
            Los datos utilizados se componen de los
            <span class="fw-bold">50</span> fichajes más caros de la historia.
          </li>
          <li>
            La información ha sido extraída del siguiente artículo:
            <a
              target="_blank"
              href="https://en.wikipedia.org/wiki/List_of_most_expensive_association_football_transfers"
              >List of most expensive association football transfers.</a
            >
          </li>
          <li>Los datos están actualizados a fecha 12 Agosto de 2021.</li>
        </ul>
        <h4 class="alert-heading">Importante</h4>
        <hr />
        <p class="mb-1">
          En todo momento la información mostrada es relativa a los 50 fichajes
          más caros de la historia. No pueden por tanto sacarse conclusiones
          sobre fichajes en el fútbol en general, las visualizaciones muestran
          qué equipos y países realizaron los fichajes más caros contabilizados
          hasta el momento.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
};
</script>

<style scoped>
.alert {
  background-color: var(--primary-light) !important;
  color: white;
  text-align: left;
}

.wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 0;
}
</style>
