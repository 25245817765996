<template>
  <transition name="fade">
    <a id="irArriba" v-on:click.prevent="irArriba" title="Volver arriba">
      <font-awesome-icon icon="arrow-up" size="lg" />
    </a>
  </transition>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "IrArriba",
  components: {
    FontAwesomeIcon,
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (scrollTop > 100) {
        document.getElementById("irArriba").classList.add("d-block");
      } else {
        document.getElementById("irArriba").classList.remove("d-block");
      }
    },
    irArriba: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
#irArriba {
  display: none;
  padding: 1.2rem;
  background: var(--primary);
  font-size: 1rem;
  color: white;
  cursor: pointer;
  position: fixed;
  bottom: 5.5rem;
  right: 0.5rem;
  transition: all 1s ease;
  border-radius: 50%;
}
</style>
