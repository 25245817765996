<template>
  <div id="chartGrid">
    <!--MODAL-->
    <Modal
      :type="typeModal"
      :id="idModal"
      :posicion="posicionModal"
      :euros="eurosModal"
      :media="mediaModal"
      :fichajes="fichajesModal"
    />

    <!--GRÁFICO-->
    <ResponsiveHeatMap
      class="bar"
      v-bind="chartCommonProps"
      :style="{ height: height + 'px' }"
    />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { ResponsiveHeatMap } from "@nivo/heatmap";

import Modal from "./Modal";

import SVG from "@/components/common/flagSVG";

export default {
  name: "Chart",
  components: {
    ResponsiveHeatMap,
    Modal,
  },
  props: {
    type: String,
    data: Array,
    avg: Number,
    avgMillones: Number,
    avgMillonesX: Number,
    height: Number,
  },
  created() {
    //console.log(this.type);
    this.idModal = `modalPosicion${this.type}`;
    if (this.type === "fichajes") {
      this.chartCommonProps.data = this.orderBy(this.data, "Fichajes", "asc");
      this.chartCommonProps.axisBottom.legend =
        "Fichajes por posicion y país en el top 50";
    } else if (this.type === "ventas") {
      this.chartCommonProps.data = this.orderBy(this.data, "Ventas", "asc");
      this.chartCommonProps.axisBottom.legend =
        "Ventas por posicion y país en el top 50";
    }
  },
  data() {
    return {
      idModal: "",
      fichajesModal: [],
      typeModal: "",
      posicionModal: "",
      eurosModal: 0,
      mediaModal: 0,
      possibleOptions: [],
      chartCommonProps: {
        //BASE
        data: this.data,
        keys: ["Portero", "Defensa", "Mediocentro", "Mediapunta", "Delantero"],
        indexBy: "PaisISO",
        forceSquare: true,
        cellShape: "circle",
        cellOpacity: 1,
        padding: 0.2,
        innerPadding: 2,
        width: 600,
        //height: 100,
        margin: { top: 0, right: 0, bottom: 80, left: 0 },

        //STYLE
        theme: {
          background: "#ffffff",
          textColor: "#333333",
          fontSize: 14,
        },
        colors: "YlOrRd",

        //LABELS
        enableLabel: true,
        label: this.label,
        labelSkipWidth: 14,
        labelSkipHeight: 14,
        labelTextColor: "#ccc",

        //GRID AND AXES
        enableGridX: false,
        //gridXValues: 1,
        enableGridY: false,
        //gridYValues: 2,
        axisTop: null,
        axisRight: null,
        axisBottom: {
          legend: "",
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 20,
          legendPosition: "middle",
          legendOffset: 70,
        },
        axisLeft: {
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: -40,
          renderTick: this.customTick,
        },

        //INTERACTIVITY
        isInteractive: true,
        //tooltip: this.tooltip,
        //onClick: this.onClick,

        //LEGENDS
        legends: [],

        //MOTION
        animate: true,
        motionConfig: "slow",

        motionStiffness: 80,
        motionDamping: 9,
        hoverTarget: "cell",

        //ACCESSIBILITY
        isFocusable: false,
      },
    };
  },
  methods: {
    label: function (e, a) {
      //console.log(e, a);
      return `${e[a]}`;
    },
    customTick: function (tick) {
      //console.log(tick);
      let size = "";
      if (this.type === "fichajes" || this.type === "ventas") {
        size = "half";
      } else {
        size = "full";
      }
      return SVG(tick.value, tick, size, this.chartCommonProps.layout);
    },
    setSelected: function (value) {
      if (value !== null) {
        //console.log("value", value);
        let selected = "";
        let asc = "asc";
        switch (value) {
          case "¿Qué se vende más?":
            selected = ["Fichajes"];
            this.chartCommonProps.axisBottom.legend =
              "Fichajes por posicion y país en el top 50";
            break;
          case "¿En qué se invierte más dinero en total?":
            selected = ["Millones de € Fichajes"];
            this.chartCommonProps.axisBottom.legend =
              "Millones de € totales gastados en fichajes por posicion en el top 50";
            break;
          case "¿En qué se invierte más dinero por fichaje?":
            selected = ["Millones de € x Fichaje"];
            this.chartCommonProps.axisBottom.legend =
              "Millones de € gastados de media en fichajes por posicion en el top 50";
            break;
          case "¿Quiénes vendieron más?":
            selected = ["Ventas"];
            this.chartCommonProps.axisBottom.legend =
              "Ventas por posicion y país en el top 50";
            break;
          case "¿Quiénes ingresaron más en total?":
            selected = ["Millones de € Ventas"];
            this.chartCommonProps.axisBottom.legend =
              "Millones de € totales ingresados en ventas por posicion en el top 50";
            break;
          case "¿Quiénes ingresaron más por fichaje?":
            selected = ["Millones de € x Venta"];
            this.chartCommonProps.axisBottom.legend =
              "Millones de € ingresados de media en ventas por posicion en el top 50";
            break;
          case "Contador":
            selected = ["Fichajes", "Ventas"];
            this.chartCommonProps.axisBottom.legend =
              "Fichajes (col. izq.) vs Ventas (col. der.) por posicion en el top 50";
            asc = "desc";
            break;
          case "Millones de €":
            selected = ["Millones de € Fichajes", "Millones de € Ventas"];
            this.chartCommonProps.axisBottom.legend =
              "Millones de € totales gastados (col. izq.) vs ingresados (col. der.) por posicion en el top 50";
            asc = "desc";
            break;
          case "Millones de € x traspaso":
            selected = ["Millones de € x Fichaje", "Millones de € x Venta"];
            this.chartCommonProps.axisBottom.legend =
              "Millones de € gastados de media en fichajes (col. izq.) vs ingresados de media en ventas (col. der.) por posicion en el top 50";
            asc = "desc";
            break;

          default:
            break;
        }
        if (selected.length === 1) {
          if (selected[0] === "Fichajes" || selected[0] === "Ventas") {
            this.chartCommonProps.markers[0].value = this.avg;
            this.chartCommonProps.markers[0].legend = `${this.avg} fichajes por posicion`;
          } else if (
            selected[0] === "Millones de € Fichajes" ||
            selected[0] === "Millones de € Ventas"
          ) {
            this.chartCommonProps.markers[0].value = this.avgMillones;
            this.chartCommonProps.markers[0].legend = `${this.avgMillones} mill €`;
          } else if (
            selected[0] === "Millones de € x Fichaje" ||
            selected[0] === "Millones de € x Venta"
          ) {
            this.chartCommonProps.markers[0].value = this.avgMillonesX;
            this.chartCommonProps.markers[0].legend = `${this.avgMillonesX} mill €`;
          }
        }
        this.chartCommonProps.data = this.orderBy(
          this.chartCommonProps.data,
          selected[0],
          asc
        );
      }
      return;
    },
    orderBy: function (array, key, asc) {
      //console.log(asc);
      //ORDEN
      if (asc === "asc") {
        array.sort(function (a, b) {
          if (a[key] > b[key]) {
            return 1;
          }
          if (a[key] < b[key]) {
            return -1;
          }
          return 0;
        });
      } else {
        array.sort(function (a, b) {
          if (a[key] < b[key]) {
            return 1;
          }
          if (a[key] > b[key]) {
            return -1;
          }
          return 0;
        });
      }
      //console.log("Datos Ordenados");
      //console.log(array);
      return array;
    },
  },
};
</script>

<style scoped>
#chartGrid {
  text-align: center;
}

.bar {
  height: 400px;
  width: 100%;
  cursor: pointer;
}
</style>

<style>
.style-chooserPosicion .vs__search::placeholder,
.style-chooserPosicion .vs__dropdown-toggle,
.style-chooserPosicion .vs__dropdown-menu {
  background: #f1f1f1;
  border: none;
  color: var(--primary);
  text-transform: uppercase;
  width: 97%;
  padding: 0.5rem;
}

.style-chooserPosicion .vs__selected,
.style-chooserPosicion .vs__dropdown-option {
  font-size: 1.2rem;
  color: var(--primary) !important;
  fill: white;
}

.style-chooserPosicion .vs__clear {
  fill: #f1f1f1;
}

.style-chooserPosicion .vs__open-indicator {
  fill: var(--primary) !important;
}

.style-chooserPosicion .vs__open-indicator,
.style-chooserPosicion .vs__selected,
.style-chooserPosicion .vs__dropdown-option {
  color: #fff;
  fill: white;
}

.style-chooserPosicion .vs__dropdown-option--highlight {
  background: #fff;
  color: var(--primary);
}
</style>
