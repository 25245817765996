<template>
  <div id="app">
    <HeaderFooter />

    <div class="container">
      <Hero />
      <hr />
      <ChartAnyoTraspaso />
      <hr />
      <PaisWrapper />
      <hr />
      <ChartEquipo />
      <hr />
      <PosicionWrapper />
      <hr />
      <ChartOrigen />
    </div>

    <IrArriba />
    <HeaderFooter />
  </div>
</template>

<script>
import HeaderFooter from "./components/common/HeaderFooter.vue";
import Hero from "./components/common/Hero.vue";
import IrArriba from "./components/common/IrArriba.vue";

import ChartAnyoTraspaso from "./components/charts/anyoTraspaso/ChartAnyoTraspaso.vue";
import PaisWrapper from "./components/charts/pais/PaisWrapper.vue";
import ChartEquipo from "./components/charts/equipo/ChartEquipo.vue";
import PosicionWrapper from "./components/charts/posicion/PosicionWrapper.vue";
import ChartOrigen from "./components/charts/origen/ChartOrigen.vue";

export default {
  name: "App",
  components: {
    HeaderFooter,
    Hero,
    IrArriba,
    ChartAnyoTraspaso,
    PaisWrapper,
    ChartEquipo,
    PosicionWrapper,
    ChartOrigen,
  },
};
</script>

<style scoped>
#app {
  font-family: Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--primary);
}

hr {
  margin: 6rem 0 5rem 0 !important;
}
</style>

<style>
a {
  color: beige !important;
}

a:hover {
  color: burlywood !important;
}

p {
  margin-bottom: 0 !important;
  width: 100%;
}

.alert-warning {
  color: #171e32 !important;
  background-color: #f1f1f1 !important;
  border-color: #171e32 !important;
}
</style>
